import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/design-suite.png";
import { Helmet } from "react-helmet";

class DesignSuite extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Design Suite, Designer Suite, Suite Design, Web Design Suite
          </title>
          <meta
            name="keywords"
            content="Designer Suite, Raster Graphics, Vector Graphics, Logo design, Brochure Design, Newsletter design, web page design, e-commerce, designer suite, web layout, logo design, raster graphics, vector graphics, pixel based, mathematical formula based, first impression, last impression, emerging businesses, entrepreneurs, consultants, agencies, resellers, private and government companies, all domains, designer suite, pioneers, co-ordinates, identification, website design software delivers, Managing Design Suite, Web Design Suite, 3D Design Suite, adobe web design suite, website design software, open- source logo web design, web design apps"
          />
          <meta
            name="description"
            content="At Avani Cimcon Technologies Ltd the pioneer team is following technologies - HTML, XHTML, Tableless HTML, Divbased HTML, CSS, Style Guide, Photoshop, Dream weaver, Flash, Action Script 2.0, Action Script 3.0, our experts work in Raster Graphics, Vector Graphics, Logo design, Brochure Design, Newsletter design, Card design, flyers, mailers, sales and marketing brochures, promotion brochures."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Design Suite</h1>
            <h5>Design Suite</h5>
          </div>
          <div
            className="page-banner-img"
            style={{ backgroundImage: `url("${BannerImg}")` }}
          ></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify" style={{ minHeight: "300px" }}>
              <p>
                Feel free to contact us anytime at{" "}
                <a href="mailto:inquiry@avanicimcon.com">
                  inquiry@avanicimcon.com
                </a>{" "}
                with your contact details, project scope and budget for further
                assistance. Thanks for visiting Avani Cimcon Technologies.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DesignSuite;
