import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/careers.png";
import { Helmet } from "react-helmet";

class Careers extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Career with Avani Cimcon Technologies Ltd, Job Openings at Avani
            Cimcon Technologies Ltd
          </title>
          <meta
            name="keywords"
            content="Information Technology Career, Job Opportunity, Apply for job, software developer jobs, Microsoft Technologies Jobs, SEO Jobs, Microsoft .Net Programmers, dotnetnuke developers, DNN developers, php programmer jobs, IT project leader job, Business Development Managers, Web Designers"
          />
          <meta
            name="description"
            content="Apply for job and nurture your career with Avani Cimcon Technologies, We have requirements best software professionals like Microsoft .Net Programmers, DotNetNuke Developers, PHP Programmers, Project Leaders, Business Development Managers, Web Designers, SEO"
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Careers</h1>
            <h5>Careers</h5>
          </div>
          <div
            className="page-banner-img"
            style={{ backgroundImage: `url("${BannerImg}")` }}
          ></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies is an open, transparent and energetic
                organization with minimal hierarchies and tremendous
                opportunities to grow. Our people are provided with platforms to
                unleash their full potential. We have diverse range of
                professionals who deliver specialized capabilities and software
                solutions to clients.
              </p>

              <p>
                Avani Cimcon Technologies is a place, where personal growth is
                nurtured through ever demanding challenges and the professional
                development of skills & concepts to meet these challenges. The
                ultimate goal is in providing IT services with total customer
                satisfaction.
              </p>

              <p>
                Whatever it is that makes you unique inculcate it with the new
                enablers of innovation and you can earn higher profits,
                penetrate new markets drive productivity - in a word, and
                differentiate yourself from the competition. You want to be
                special. We specialize in making you special.
              </p>

              <p>
                Email your resumes to{" "}
                <a href="mailto:inquiry@avanicimcon.com">
                  inquiry@avanicimcon.com
                </a>{" "}
                (Resumes as attachments)
              </p>

              <h5 className="text-primary mt-4 mb-3">Current Openings :</h5>

              <div className="shadow-sm p-3">
                <h4 className="text-primary mb-3 font-weight-bold">
                  Senior Software Engineer, Team Lead AND Sr. Technical Lead
                </h4>
                <p>
                  <b>Job Location: Ahmedabad</b>
                </p>
                <b>Experience :</b>
                <ul className="mt-2">
                  <li className="mb-2">3 + years of development experience</li>
                  <li>
                    IT Product Development Experience would be a added advantage
                  </li>
                </ul>
                <b>Technical Skill :</b>
                <ul className="mt-2">
                  <li className="mb-2">
                    MUST have Expertise (Well Versed) in .NET 2.0, C#, .Net 2.0
                    Class Library, OOP, Ajax, VB, SQL Server, ASP.Net &amp;
                    ADO.Net (AND, OR)
                  </li>
                  <li>
                    Excellent Understanding of (Advance level) XML, XSLT, XSD,
                    Java Script/Query, HTML/CSS, Web Services &amp; Multi
                    Threading.
                  </li>
                </ul>
                <b>Responsibilities :</b>
                <ul className="mt-2">
                  <li className="mb-2">
                    Responsible for Support, Development and Enhancement of
                    Projects
                  </li>
                  <li className="mb-2">
                    Responsible to go in depth of Analysis and to maintain the
                    standards of Designs &amp; development along with Technical
                    Code Documentation.
                  </li>
                  <li className="mb-2">
                    Responsible to work independently with minimal supervision
                    and as a part of team.
                  </li>
                  <li className="mb-2">
                    Responsible to maintain the client relationship (on need
                    base only).
                  </li>
                  <li className="mb-2">
                    Responsible to define the application architecture and
                    tools/techniques required for the project, ensuring the
                    requirement of business needs.
                  </li>
                  <li className="mb-2">
                    Responsible to resolve high level Technical issues.
                  </li>
                  <li className="mb-2">
                    Responsible to determine data requirements, structure and
                    distribution.
                  </li>
                  <li className="mb-2">
                    Responsible to work on Proof of Concept (POC) on any new
                    initiatives.
                  </li>
                  <li className="mb-2">
                    Responsible to manage a complex, distributed, high
                    performance application.
                  </li>
                  <li className="mb-2">
                    Responsible for technology risk management &amp; mitigation.
                  </li>
                  <li className="mb-2">
                    Responsible to provide Pre-sales support in doing technical
                    research for client proposals, to enable the sales team to
                    respond to new leads.
                  </li>
                  <li className="mb-2">
                    Responsible to keep a tab on emerging technologies, analysis
                    &amp; adoption of the same throughout the company / project
                    / product.
                  </li>
                </ul>
                <p>
                  Candidate who fit into above requirement, please forward your
                  cv to{" "}
                  <a href="mailto:inquiry@avanicimcon.com">
                    inquiry@avanicimcon.com
                  </a>
                </p>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h4 className="text-primary mb-3 font-weight-bold">
                  UI Designer
                </h4>
                <p>
                  <b>Job Location: Ahmedabad</b>
                </p>
                <b>Experience :</b>
                <ul className="mt-2">
                  <li className="mb-2">3 + years of experience</li>
                  <li>
                    International Projects web design would be a added advantage
                  </li>
                </ul>
                <b>Technical Skill :</b>
                <ul className="mt-2">
                  <li className="mb-2">
                    Must have sound skill in Photoshop, CorelDraw & illustrator.
                  </li>
                </ul>
                <b>Responsibilities :</b>
                <ul className="mt-2">
                  <li className="mb-2">
                    Responsible for designing user interface elements for highly
                    competitive online website receiving users in the large
                    quantum.
                  </li>
                  <li className="mb-2">
                    Responsible for creating and arranging of the pages which
                    make up a website and balancing of the same towards its
                    functionality.
                  </li>
                  <li className="mb-2">
                    Responsible for visualization and creation of designs with
                    required color scheme.. etc., (like – a webpage, a website,
                    an e-mail Newsletter, a brochure, an icon, a logo)
                  </li>
                  <li className="mb-2">
                    Responsible for understanding the client requirement
                    subsequently review the existing similar site / presentation
                    coupled with latest trends and translation of the
                    understanding into images/layouts.
                  </li>
                  <li className="mb-2">
                    Responsible for designing of corporate presentations,
                    concept development, original art creation and designing the
                    look of multi-media components.
                  </li>
                  <li className="mb-2">
                    Responsible for email designing and standards
                  </li>
                  <li className="mb-2">
                    Responsible for translating functional requirements into
                    detailed visual design by working closely with Project
                    Managers and clients.
                  </li>
                </ul>
                <p>
                  Candidate who fit into above requirement, please forward your
                  cv to{" "}
                  <a href="mailto:inquiry@avanicimcon.com">
                    inquiry@avanicimcon.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Careers;
