import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/events.png";
import { Helmet } from "react-helmet";
import logo1 from "../../assets/images/events/singapore.gif";
import logo2 from "../../assets/images/events/may_london.gif";
import logo3 from "../../assets/images/events/travel_summit2007.gif";
import logo4 from "../../assets/images/events/eyefortravel.gif";
import logo5 from "../../assets/images/events/worldtravel.gif";
import logo6 from "../../assets/images/events/Itb_Asia_Past.gif";
import logo7 from "../../assets/images/events/WTM_10_13_Nov.gif";
import logo8 from "../../assets/images/events/ITB_Berlin_Past.gif";
import logo9 from "../../assets/images/events/ITB_Asia.gif";
import logo10 from "../../assets/images/events/TheTravelInnovationSummit.jpg";

class Events extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Events | Avani Cimcon Technologies Ltd</title>
          <meta name="keywords" content="" />
          <meta name="description" content="" />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Events</h1>
            <h5>Events</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <h5 className="text-primary mb-3">
                TravelCarma to showcase its Innovation at PhoCusWright’s “The Travel Innovation
                Summit”
              </h5>
              <img className="pull-left" src={logo10} alt="" />
              <p>
                TravelCarma’s Innovative Travel Technology has been selected by PhoCusWright to
                showcase at “The Travel Innovation Summit” on 15 Nov 2011, USA. TravelCarma is proud
                to share space amongst world’s leading 30 innovators whose innovations are expected
                to forever change the face of travel planning, purchasing and memorializing.
              </p>
              <p>
                In the ever-growing online travel space, travel companies without significant online
                presence are losing customers, market-share and revenue against their online
                competitors. TravelCarma empowers these companies by providing them e-commerce
                enabled complete online travel portals with their own branding and content, mobile
                and social media booking portals and access to global travel inventory. The
                pay-as-you-go SaaS model makes the technology available with no upfront investments.
              </p>
              <p>
                From the technology standpoint, TravelCarma’s innovation – “A Cloud-based
                Applications Delivery Platform integrated with an Open-Distribution based
                TravelXchange” provides travel companies with multi-platform POS and distribution of
                travel content across multiple channels.
              </p>
              <p>
                TravelCarma will be available for demonstration, discussion and consultation at the
                event during 15th – 17th November 2011. To fix up an appointment, please email at{" "}
                <a href="mailto:sales@travelcarma.com" className="text-primary" alt="">
                  sales@travelcarma.com
                </a>
              </p>
            </div>

            <h5 className="text-primary mt-4 mb-3 font-weight-bold">Past Events:</h5>

            <ul className="row list-unstyled">
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo1} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo2} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo3} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo4} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo5} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo6} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo7} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo8} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo9} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center pt-3 pb-3">
                  <img src={logo10} alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Events;
