import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/contact-us.png";
import { Helmet } from "react-helmet";
// import ContactForm from "../../components/form/contact-form";
import ContactInfo from "../../components/form/contact-info";
//import Iframe from "react-iframe";

class ContactUs extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Contact Avani Cimcon Technologies Ltd for Software Development,
            Software Application Development, Solution Provider Company,
            Software solutions
          </title>
          <meta
            name="keywords"
            content="offshore software development, software development, offshore it services, web application development, IT support service, travel solutions, health care solutions, CMS, business software services, custom software programming services, It consulting services, Designer Suite, system integration, Avani Cimcon Technologies Ltd contact us"
          />
          <meta
            name="description"
            content="Contact Avani Cimcon Technologies Ltd for Offshore Software Development, Web applications, IT Services, Business Software Services, IT Solutions, system integration, Applications Development in DNN"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Contact Us</h1>
            <h5>Contact Us</h5>
          </div>
          <div
            className="page-banner-img"
            style={{ backgroundImage: `url("${BannerImg}")` }}
          ></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                {/* <div className="shadow-sm p-4">
                  <h5 className="text-primary mb-4" style={{display:"none"}}>US Office - Marketing and Sales</h5>
                  <h5 className="text-primary mb-4">US Office</h5>
                  <p>
                    <b>Address : </b> Avani Cimcon Technologies INC, 12482, Turnberry Dr., Jacksonville, FL 32225-4602
                  </p>
                  <p>
                    <b>Tel : </b> +1 904 328 3090 | +1 904 236 4868
                  </p>
                  <p>
                    <b>Fax :</b> +44 203 051 6811
                  </p>
                </div> */}
                <div className="shadow-sm p-4">
                  <h5 className="text-primary mb-4">India Office</h5>
                  <p>
                    <b>Tel :</b> 91-79-26872171 / 049 (9:00 AM to 6:00 PM, IST)
                  </p>
                  {/* <p>
                    <b>Fax :</b> 91-79-26872219 / 170
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6">
                <ContactInfo />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                {/* <ContactForm /> */}
                {/* <Iframe
                  url="http://travel.avanicimcon.com/InquiryForm.asp"
                  width="100%"
                  height="550px"
                  id="myId"
                  className="myClassname mt-4"
                  display="initial"
                  position="relative"
                  frameBorder="0"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUs;
