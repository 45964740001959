import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="pt-4 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-3 light-border">
                <h6 className="text-white">Company</h6>
                <ul className="list-unstyled p-0 m-0 small">
                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/about">
                      About us
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/management">
                      Management
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/expertise-experience">
                      Expertise & Experience
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/advantage">
                      ACT Advantage
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/value-proposition">
                      Value Proposition
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/news">
                      News / Press Release
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/events">
                      Events
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/membership">
                      Membership
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 light-border">
                <h6 className="text-white">Products</h6>

                <ul className="list-unstyled p-0 m-0 small">
                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/dxchange">
                      DXchange
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/travel-hospitality">
                      Travel & Hospitality
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/insurance">
                      Insurance
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/healthcare">
                      Healthcare
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/legal">
                      Legal
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/biz-rule-engine">
                      BizRuleEngine
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/cms">
                      CMS
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/umrah-booking-solution">
                      Umrah Booking Solution
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/more-products">
                      More Products
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 light-border">
                <h6 className="text-white">Services</h6>

                <ul className="list-unstyled p-0 m-0 small">
                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/it-consulting-services">
                      IT Consulting Services
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/design-suite">
                      Design Suite
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/project-management">
                      Project Management
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/microsoft-technologies">
                      Microsoft Technologies
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/dotnetnuke-development">
                      DotNetNuke Development
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/java-development">
                      Java Development
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/quality-assurance">
                      Quality Assurance
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/quality-assurance">
                      Mobile App Development
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 light-border">
                <h6 className="text-white">Customers</h6>
                <ul className="list-unstyled p-0 m-0 small">
                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/international-acceptance">
                      International Acceptance
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/international-projects">
                      International Projects
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/domestic-projects">
                      Domestic Projects
                    </Link>
                  </li>
                </ul>

                <h6 className="text-white mt-4">Partners</h6>

                <ul className="list-unstyled p-0 m-0 small">
                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/objective">
                      Objective
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/benefits">
                      Benefits
                    </Link>
                  </li>

                  <li className="mb-1 d-flex align-items-center text-white">
                    <i
                      className="fa fa-angle-double-right mr-2"
                      aria-hidden="true"
                    />
                    <Link className="text-white" to="/partners-program">
                      Partners Program
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <h6 className="text-primary">Contact Us</h6>
            <ul className="list-unstyled p-0 m-0 small">
              {/* <li className="mb-2 d-flex align-items-center text-white">
                <i className="fa fa-phone-square mr-2" aria-hidden="true" />
                <b className="mr-2">US :</b>+1 904 328 3090
              </li>
              <li className="mb-2 d-flex align-items-center text-white">
                <i className="fa fa-phone-square mr-2" aria-hidden="true" />
                <b className="mr-2">UK :</b>+44 203 051 6811
              </li> */}
              <li className="mb-2 d-flex align-items-center text-white">
                <i className="fa fa-phone-square mr-2" aria-hidden="true" />
                <b className="mr-2">Ind :</b>+91 792 687 2171
              </li>
              {/* <li className="mb-2 d-flex align-items-center text-white">
                <i className="fa fa-envelope mr-2" aria-hidden="true" />
                <b className="mr-2">Sales :</b>
                <a className="text-white" href="mailto:sales@avanicimcon.com">
                  sales@avanicimcon.com
                </a>
              </li> */}
              <li className="mb-2 d-flex align-items-center text-white">
                <i className="fa fa-envelope mr-2" aria-hidden="true" />
                <b className="mr-2">Info :</b>
                <a className="text-white" href="mailto:inquiry@avanicimcon.com">
                  inquiry@avanicimcon.com
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="copyright col-lg-12 text-center mt-3 pt-2">
            <small className="text-white">
              Copyright 2020. Avani Cimcon Technologies All rights reserved
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
