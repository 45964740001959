import React from "react";

const HomeWhyUs = () => {
  return (
    <div className="home-whyus mt-5 mb-5">
      <div className="container">
        <h2 className="page-sub-title text-center mb-4 font-weight-bold">Why Us</h2>
        <ul className="list-unstyled p-0 m-0 row">
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Cutting-edge solutions with huge market potential
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Modular and configurable solutions customized for industry verticals
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Solutions built on proprietary SOA
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Applications designed around the solutions provide additional value
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Solutions already deployed and client-accepted
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Unique marketing propositions along with experience and expertise
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
              Quality focus (IS/ISO 9001:2008 certified)
            </div>
          </li>
          <li className="col-lg-6 mb-3">
            <div className="d-flex shadow-sm p-2 rounded align-items-center">
              <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>A large
              pool of talented and experienced resources with proven experience of doing business
              offshore
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeWhyUs;
