import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/dxchange.png";
import DXchangeIPaaS from "../../assets/images/DXchange-iPaaS.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class DXchange extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Data Exchange, Data Exchange Middleware Server, Dxchange -
            Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Middleware Software Solutions, Data Exchange Middleware Server, Data Exchange Middleware Server software, d exchange, middleware server, Exchange server, Exchange Web services XM, SOA middleware, xml middleware, biztalk middleware, xml software, data exchange, data integration software, xml applications, XML web services, Business Rules Engine, Exchange server, Dxchange- xml middleware software, middleware software directory, Exchange Web Service, XML Web Services API: Integration, Middleware, xml Technology, Middleware Software, XML Web Services Middleware, Middleware Software, Middleware Solutions, Middleware Server, Middleware Systems"
          />
          <meta
            name="description"
            content="DXchange is xml based data exchange middle ware server available in three versions: DXchange - Lite, DXchange - Professional, DXchange - Enterprise, XML Software, A powerful web-based Business Rules Engine with an easy to use web interface is integrated with the DXchange server, XML- managing data exchange web services, XML-based Web Services APIs, Middleware (XML Web Services) - Middleware Software, Middleware Solutions (India) - Travel, Insurance, Law Firms, Legacy Systems, XML Technology, Middleware Server, Middleware- Travel (OTA)"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">DXchange</h1>
            <h5>DXchange</h5>
          </div>
          <div
            className="page-banner-img"
            style={{ backgroundImage: `url("${BannerImg}")` }}
          ></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                DXchange is an innovative product of Avani Cimcon Technologies
                http://dxchange.travelcarma.com/ It is an XML web services
                based, fully scalable, easily configurable Data Exchange
                Middleware Server. DXchange provides infrastructure for an
                organization or an application to form a powerful XML web
                services based communications backbone for the organization. It
                is built on the concept of an Enterprise Service Bus (ESB).
                Utilizing custom adaptors, existing applications and data can be
                integrated with the DXchange bus allowing them to be exposed /
                accessed as Web Services (SaaS). Individual components can be
                customized for each application/industry vertical making the
                system extremely flexible and scalable.
              </p>
            </div>

            <div className="text-center mt-4">
              <a
                className="text-primary"
                rel="noopener noreferrer"
                href="http://dxchange.travelcarma.com"
                target="_blank"
              >
                <b>Visit DXchange Website</b>
              </a>
            </div>

            <div className="mt-3 text-center">
              <img src={DXchangeIPaaS} style={{ width: "80%" }} alt="" />
            </div>

            <h5 className="text-primary mt-5 mb-3">
              DXchange is available in three versions:
            </h5>

            <div className="row mb-5">
              <div className="col-lg-4 d-flex">
                <div className="shadow-sm p-3 text-center">
                  <h5 className="text-primary font-weight-bold mb-3">
                    DXchange – Lite
                  </h5>
                  <p>
                    Lite allows individual applications to either consume data
                    from external sources or provide data as service to external
                    sources.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="shadow-sm p-3 text-center">
                  <h5 className="text-primary font-weight-bold mb-3">
                    DXchange – Professional
                  </h5>
                  <p>
                    Professional allows applications to consume data from
                    external sources and also provide their own data as
                    services. It acts as a true middleware gateway to systems
                    and also allows pass through connections.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 d-flex">
                <div className="shadow-sm p-3 text-center">
                  <h5 className="text-primary font-weight-bold mb-3">
                    DXchange – Enterprise{" "}
                  </h5>
                  <p>
                    Enterprise provides infrastructure for an Enterprise Service
                    Bus. It allows any and every application connections and
                    comes along with a powerful business rules engine with a Web
                    UI. Each version can be scaled to the next one easily.
                  </p>
                </div>
              </div>
            </div>

            <p>
              DXchange is platform agnostic as all interfaces to it are through
              web services including the admin and maintenance interfaces.
            </p>

            <p>
              The integrated Business Rules Engine gives the flexibility to keep
              pace with ever-changing business requirements without any
              programming or code changes. DXchange allows organizations to
              expose their data as a Service or allow their applications to be
              exposed as a Service for consumption by other authorized
              applications.
            </p>

            <p>
              DXchange has been recognized by NASSCOM to be profiled in their
              prestigious listing of 100 IT Innovators. (
              <a
                rel="noopener noreferrer"
                href="http://www.nasscom.in/Nasscom/templates/NormalPage.aspx?id=53050"
                alt="100itinnovators"
                target="_blank"
                className="text-primary"
              >
                www.nasscom.in/100itinnovators
              </a>
              )
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DXchange;
