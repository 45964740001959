import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./screens/home";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./screens/company/about";
import Management from "./screens/company/management";
import ExpertiseExperience from "./screens/company/expertise-experience";
import Advantage from "./screens/company/advantage";
import ValueProposition from "./screens/company/value-proposition";
import DXchange from "./screens/products/dxchange";
import UmrahBookingSolution from "./screens/products/umrah-booking-solution";
import TravelAndHospitality from "./screens/products/travel-hospitality";
import Insurance from "./screens/products/insurance";
import Healthcare from "./screens/products/healthcare";
import Legal from "./screens/products/legal";
import BizRuleEngine from "./screens/products/biz-rule-engine";
import CARMA from "./screens/products/carma";
import CMS from "./screens/products/cms";
import OnlineExamination from "./screens/products/online-examination";
import MoreProducts from "./screens/products/more-products";
import ITConsultingServices from "./screens/services/it-consulting-services";
import DesignSuite from "./screens/services/design-suite";
import MicrosoftTechnologies from "./screens/services/microsoft-technologies";
import DotNetNukeDevelopment from "./screens/services/dotnetnuke-development";
import ProjectManagement from "./screens/services/project-management";
import JavaDevelopment from "./screens/services/java-development";
import QualityAssurance from "./screens/services/quality-assurance";
import InternationalAcceptance from "./screens/customers/international-acceptance";
import InternationalProjects from "./screens/customers/international-projects";
import DomesticProjects from "./screens/customers/domestic-projects";
import Objective from "./screens/partners/objective";
import Benefits from "./screens/partners/benefits";
import PartnersProgram from "./screens/partners/partners-program";
import News from "./screens/news/news";
import Events from "./screens/news/events";
import Membership from "./screens/news/membership";
import Careers from "./screens/careers/careers";
import ContactUs from "./screens/contact/contact-us";
import ReactGA from "react-ga";
//import TagManager from "react-gtm-module";

ReactGA.initialize("UA-5235714-2");
class App extends Component {
  state = {};
  
  componentDidUpdate() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    // const tagManagerArgs = {
    //   gtmId: "GTM-KX7FDMF",
    // };
    // TagManager.initialize(tagManagerArgs);
    //ReactGA.initialize("UA-5235714-2");
    //ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <React.Fragment>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/management" component={Management} />
          <Route path="/expertise-experience" component={ExpertiseExperience} />
          <Route path="/advantage" component={Advantage} />
          <Route path="/value-proposition" component={ValueProposition} />
          <Route path="/dxchange" component={DXchange} />
          <Route path="/umrah-booking-solution" component={UmrahBookingSolution} />
          <Route path="/travel-hospitality" component={TravelAndHospitality} />
          <Route path="/insurance" component={Insurance} />
          <Route path="/healthcare" component={Healthcare} />
          <Route path="/legal" component={Legal} />
          <Route path="/biz-rule-engine" component={BizRuleEngine} />
          <Route path="/carma" component={CARMA} />
          <Route path="/cms" component={CMS} />
          <Route path="/online-examination" component={OnlineExamination} />
          <Route path="/more-products" component={MoreProducts} />
          <Route path="/it-consulting-services" component={ITConsultingServices} />
          <Route path="/design-suite" component={DesignSuite} />
          <Route path="/microsoft-technologies" component={MicrosoftTechnologies} />
          <Route path="/dotnetnuke-development" component={DotNetNukeDevelopment} />
          <Route path="/project-management" component={ProjectManagement} />
          <Route path="/java-development" component={JavaDevelopment} />
          <Route path="/quality-assurance" component={QualityAssurance} />
          <Route path="/international-acceptance" component={InternationalAcceptance} />
          <Route path="/international-projects" component={InternationalProjects} />
          <Route path="/domestic-projects" component={DomesticProjects} />
          <Route path="/objective" component={Objective} />
          <Route path="/benefits" component={Benefits} />
          <Route path="/partners-program" component={PartnersProgram} />
          <Route path="/news" component={News} />
          <Route path="/events" component={Events} />
          <Route path="/membership" component={Membership} />
          <Route path="/careers" component={Careers} />
          <Route path="/contact-us" component={ContactUs} />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
