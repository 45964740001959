import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/more-products.png";
import { Helmet } from "react-helmet";

class MoreProducts extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Custom Software Development Solutions, IT solutions, Software Solution - Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Custom Software Development Solutions, IT solutions, Software Solution, custom software development, custom application development, custom software solutions website, Custom Software Development, document management solutions, custom software development solutions, cms software solutions, content management system, crm software solutions, custom software solution, customizable software solutions, customized software solutions, document management software, document management systems, erp software, erp software solutions, India solutions, information technology solutions, software development, solutions technologies, web content management system, Marketing solutions, Insurance solutions, Collaboration tools, Utilities "
          />
          <meta
            name="description"
            content="Avani Cimcon Technologies based in Ahmedabad, India provides world-class professional services for business service management, content management, applications management, Software, Databases, Web and E-Commerce development using the latest technologies. Build on Service Oriented Architecture that allows easy integration of our ready to use, customizable, co-brand able products, e-solutions into existing applications and products. Some of these products are developed on the concept of SaaS (Software as a Service)."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">More Products</h1>
            <h5>More Products</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                We have developed number of software solutions utilizing our vast experience of 15
                years in software development across the technologies. We at Avani Cimcon
                Technologies (ACT) are using some of these solutions in our day-to-day operations.
                These ready to use and customizable solutions are for sale and categorized under
                four major categories:
              </p>

              <ul>
                <li className="mb-2 text-primary">
                  <b>Marketing solutions</b>
                </li>
                <li className="mb-2 text-primary">
                  <b>Insurance solutions</b>
                </li>
                <li className="mb-2 text-primary">
                  <b>Collaboration tools</b>
                </li>
                <li className="text-primary">
                  <b>Utilities</b>
                </li>
              </ul>

              <h5 className="text-primary mt-4">Marketing Solutions:</h5>
              <p>
                A comprehensive toolkit consisting of concepts, tools and utilities for e-marketing.
                Marketing solutions enhance our client’s / partner’s e-CRM products and extend the
                features and functionalities to their existing systems. Build on Service Oriented
                Architecture that allows easy integration of our ready to use, customizable,
                co-brandable products, e-solutions into existing applications and products. Some of
                these products are developed on the concept of SaaS (Software as a Service).
              </p>

              <h5 className="text-primary mt-4">Insurance Solutions:</h5>
              <p>
                Insurance solutions showcase our experience in Insurance for more than 5 years.
                Insurance solutions are customizable and unique solutions. Resources, skill sets and
                processes focused on the Insurance vertical.
              </p>

              <h5 className="text-primary mt-4">Collaboration Tools:</h5>
              <p>
                Collaboration Tools are the one that helps in managing projects, client
                relationships and rapid application development. Collaboration tools are useful in
                day-to-day operation, making the software development and executing processes much
                simpler. These tools are complete products by themselves and are developed with the
                concept of facilitating in building better business relations.
              </p>

              <h5 className="text-primary mt-4">Utilities:</h5>
              <p>
                These utilities are 100% web based, pluggable and customizable. These utilities
                serve the purpose of facilitating for smooth, quick and quality output. Each of
                these utilities are developed with an idea of supporting client’s company software
                infrastructure and help them to serve their clients in a better and professional
                way.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MoreProducts;
