import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/java-development.png";
import { Helmet } from "react-helmet";

class JavaDevelopment extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Java Web Services, Java Software Development Company, Customized Java Development
            Solutions, Outsourcing Java Development - avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="java development services, java developers, java application development, outsourcing java development, offshore java development, Hire Java Developers, Customized Java Development Solutions, Java solutions and services, java solutions architects, java solutions architecture, java ecommerce platform, java solutions embedded, java system solutions, java development, Microsoft java development, javascript development, java technologies, java program, java applications, java web development framework, java development frameworks, java api, java software application, java web application, J2EE Application developers, web development services"
          />
          <meta
            name="description"
            content="ACT Java Framework is robust and flexible and addresses specific types of work that an organization undertakes. Java Software Development with talented Java Developers at ACT has been a couple for delivering the great Java technology advancement to the clients from all across the world. We offer Offshore Java development services for the companies who need to outsource their Java Development."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Java Development</h1>
            <h5>Java Development</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies (ACT) is one of the pioneers in developing applications in
                Java technologies. Our core strategy for Java Development is to develop a robust and
                a versatile generic framework that allows us to build business centric applications.
                In the development of this framework we have utilized our vast and varied experience
                gained over a number of years by developing software and products for different
                customers from all over the world. The other key strategy has been to invest in
                training and gaining expertise on industry standard development platforms available
                in Java such as Weblogic and IBM Websphere.
              </p>

              <p>
                We have invested time in architecting, designing, developing and testing the Java
                Framework. An experienced and a dedicated team have enabled us to create this
                framework in a short period of time. ACT Java Framework is robust and flexible and
                addresses specific types of work that an organization undertakes.
              </p>

              <p>
                For maintenance, ACT’s resource pool has experienced senior programmers, who
                maintain and enhance the Java Framework, always in a secured environment.
              </p>

              <h5 className="text-primary mt-4 mb-3">
                The advantages of this Framework are listed below:
              </h5>

              <ul>
                <li className="mb-2">Re-usability of Code</li>
                <li className="mb-2">Rapid Development</li>
                <li className="mb-2">Designers and Developers can work simultaneously</li>
                <li className="mb-2">Changes in the Layout</li>
                <li className="mb-2">Changes in Business Logic</li>
                <li className="mb-2">Recursive Processing</li>
                <li className="mb-2">Dynamic Content</li>
                <li>Web Services</li>
              </ul>

              <h5 className="text-primary mt-4 mb-3">Our Skillset</h5>

              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="bg-light font-weight-bold">Core APIs</td>
                    <td>
                      Servlets, Java Server Pages (JSP), Tag libraries, Struts, J2ME, EJB,
                      Hibernate, Java Transactions API, Java Mail API.
                    </td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Java XML</td>
                    <td>DOM & SAX, Xerces</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Java Performance</td>
                    <td>
                      JVM tuning, Memory and Disk Caching, Java I/O Performance, JUnit Testing
                    </td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Web Services</td>
                    <td>SOAP, RPC, WSDL, UDDI</td>
                  </tr>
                </tbody>
              </table>

              <p>
                With excellent technical resources and years of experience, ACT is in a position of
                proving itself as one of the best choices for Java solutions and services.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JavaDevelopment;
