import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/international-acceptance.png";
import { Helmet } from "react-helmet";

class InternationalAcceptance extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>International Acceptance, Customer Acceptance – Customer – avaniCimcon.com</title>
          <meta
            name="keywords"
            content="Software Development Services, Web Application Development India, web development testimonials, web development, USA, Europe, Middle East, Asia Pacific."
          />
          <meta
            name="description"
            content="We are Software Development Company, India providing Software development and web application development services and solution. Our team works 24/7 customer services with quality work in time turn around time."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">International Acceptance</h1>
            <h5>International Acceptance</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <table className="table table-bordered">
              <thead>
                <tr className="bg-light">
                  <th>USA</th>
                  <th>EUROPE</th>
                  <th>MIDDLE-EAST</th>
                  <th>ASIA-PACIFIC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul className="list-unstyled p-0 m-0">
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        California
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Arkansas
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        New Jersey
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Florida
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Caribbean Islands
                      </li>
                    </ul>
                  </td>

                  <td>
                    <ul className="list-unstyled p-0 m-0">
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        United Kingdom
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Greece
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Germany
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Austria
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        France
                      </li>
                    </ul>
                  </td>

                  <td>
                    <ul className="list-unstyled p-0 m-0">
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        UAE
                      </li>
                    </ul>
                  </td>

                  <td>
                    <ul className="list-unstyled p-0 m-0">
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        India
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Malaysia
                      </li>
                      <li className="d-flex align-items-center mb-2 mt-2">
                        <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                        Australia
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InternationalAcceptance;
