import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/news.png";
import { Helmet } from "react-helmet";
import TheTravelInnovationSummit from "../../assets/images/events/TheTravelInnovationSummit.jpg";
import Nasscom01 from "../../assets/images/events/Nasscom01.jpg";
import Nasscom02 from "../../assets/images/events/Nasscom02.jpg";
import Nasscom03 from "../../assets/images/events/Nasscom03.jpg";
import ITInnovators from "../../assets/images/events/ITInnovators.gif";

class News extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Press Releases, News – avanicimcon.com</title>
          <meta
            name="keywords"
            content="Offshore software development-commerce solutions, shopping cart software, security solution provider, news, press release, Aruba hotel and tourism association (ahata), thai hotel and hospitality management association, Travel Technology Consultants, global IT Company specializing"
          />
          <meta
            name="description"
            content="News and Press Releases of Avani Cimcon - Offshore Software Development & IT Outsourcing Company"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">News / Press Release</h1>
            <h5>News / Press Release</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <h3 className="text-primary p-0 m-0 mb-3 font-weight-bold">News</h3>
              <div className="shadow-sm p-3">
                <img className="pull-left mr-4" src={ITInnovators} alt="" />

                <p>
                  Avani Cimcon Technologies is proud to announce the recent achievement of our
                  software solution - DXchange. We have been short-listed by NASSCOM to be profiled
                  in the listing of NASSCOM's 100 IT Innovators - 2007 at
                  www.nasscom.in/100itinnovators. DXchange is short-listed as one of the best
                  innovation in the category of "Market facing innovation". This initiative of
                  NASSCOM recognizes the organizations that have made innovation a part of their DNA
                  and developed extraordinary and pioneering products and services. It showcases
                  innovation in India and aims to provide a perspective on the concept of innovation
                  and on how Indian IT-ITES companies can explore its various facets.
                </p>

                <p>
                  Innovation is an important part of our growth strategies and has emerged with an
                  Intellectual Property. It is an implied promise to our customers and prospects
                  that we are striving constantly to provide with all new technological
                  possibilities helping them achieve their business objective.
                </p>

                <p>
                  The NASSCOM IT Innovation in India award aims to encourage, support and reward
                  innovative and breakthrough solutions, approaches and recognizes the people and
                  the organizations behind these endeavors. Avani Cimcon Technologies is proud to be
                  a part of this wonderful league of innovators!
                </p>
              </div>

              <h3 className="text-primary p-0 m-0 mb-3 font-weight-bold mt-4">Press Release</h3>
              <div className="shadow-sm p-3">
                <h5 className="text-primary mb-3">
                  TravelCarma to showcase its Innovation at PhoCusWright’s “The Travel Innovation
                  Summit”
                </h5>
                <img className="pull-left" src={TheTravelInnovationSummit} alt="" />
                <p>
                  Oct 2011: Travel Technology provider TravelCarma has been selected to showcase its
                  Innovation at PhoCusWright’s "The Travel Innovation Summit” on 15 Nov 2011, US.
                  TravelCarma, the sole entry from India, is proud to share space amongst world’s
                  leading 30 innovators who will demonstrate their applications, technologies and
                  solutions which they expect will forever change the face of travel planning,
                  purchasing and memorializing.
                </p>
                <p>
                  "After being awarded twice for Innovation (Top 100 Innovators in 2007 & Top
                  SaaS-AppS in 2011), we continued our efforts towards developing innovative
                  products for travel industry. While industry has just started talking about
                  importance of Cloud Computing and SaaS in Travel industry, we have already
                  launched our product on Travel Cloud." said Saurabh Mehta, CEO and Technical
                  Visionary – the guiding force behind all innovations at TravelCarma.
                </p>
                <p>
                  In the ever-growing online travel space, travel companies without significant
                  online presence are losing customers, market-share and revenue against their
                  online competitors. TravelCarma empowers these companies by providing them
                  e-commerce enabled complete online travel portals with their own branding and
                  content, mobile and social media booking portals and access to global travel
                  inventory. The pay-as-you-go SaaS model makes the technology available with no
                  upfront investments.
                </p>
                <p>
                  From the technology standpoint, TravelCarma’s innovation – “A Cloud-based
                  Applications Delivery Platform integrated with an Open-Distribution based
                  TravelXchange” provides travel companies with multi-platform POS and distribution
                  of travel content across multiple channels.
                </p>

                <h5 className="text-primary">About TravelCarma</h5>
                <p>
                  TravelCarma is promoted by Avani Cimcon Technologies – an IS/ISO 9001:2008
                  certified / CMM level 3, software services and solutions provider having offices,
                  resellers, partners and representatives in US, UK, Argentina, India, Australia,
                  Germany, Malaysia, Thailand, UAE and Aruba. For more than 20 years we have been
                  working with companies across the globe and have helped them leverage technology
                  for their business growth. For more information, please visit{" "}
                  <a
                    className="text-primary"
                    rel="noopener noreferrer"
                    href="http://www.travelcarma.com"
                    target="_blank"
                  >
                    www.travelcarma.com.
                  </a>
                </p>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h5 className="text-primary mb-3">
                  "TravelCarma" awarded as best SaaS system for Travel industry
                </h5>
                <p>
                  TravelCarma's suite of SaaS based solutions for Online Booking and Distribution,
                  bags award of "Top 8 SaaS-AppS"at NASSCOM EMERGEOUT Conclave on 7th June 2011,
                  India. From the list of 50+ SaaS systems, TravelCarma is recognized as the best in
                  Travel industry segment.
                </p>
                <p>
                  NASSCOM along with Zinnov did the evaluation based on criteria such as: uniqueness
                  of the product, customers, no. of users for the product, marketability and the
                  potential, online support, usability, performance, and upgrade capability etc.
                </p>
                <p>
                  "After being awarded as Top 100 Innovators in 2007, we continued our efforts
                  towards developing other innovative products, this time for travel & hospitality
                  industry. While industry has just started talking about importance of Cloud
                  Computing and SaaS in Travel & Hospitality industry, we have already launched our
                  product on Travel Cloud." says Mr. Saurabh Mehta, CEO, TravelCarma.
                </p>

                <div className="row mt-4 mb-4">
                  <div className="col-lg-4">
                    <img src={Nasscom01} alt="" style={{ width: "100%", height: "100%" }} />
                  </div>
                  <div className="col-lg-4">
                    <img src={Nasscom02} alt="" style={{ width: "100%", height: "100%" }} />
                  </div>
                  <div className="col-lg-4">
                    <img src={Nasscom03} alt="" style={{ width: "100%", height: "100%" }} />
                  </div>
                </div>

                <h5 className="text-primary mb-3">About TravelCarma</h5>
                <p>
                  TravelCarma, promoted by Avani Cimcon Technologies., is a suite of enterprise
                  level, completely modular SaaS solutions that provides complete IT infrastructure
                  for Travel & Hospitality businesses. It enables the companies leveraging IT for
                  their business growth at an affordable price. TravelCarma also provides
                  first-of-its-kind Social e-commerce system which includes a complete booking
                  portal with booking engine and other modules which runs entirely inside facebook.
                  The solutions caters to all the entities of travel & hospitality industry
                  including small to enterprise travel agent, tour operator, hotels, wholesalers,
                  restaurants, buses, tourism organizations, car rentals, etc.
                </p>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h5 className="text-primary mb-3">
                  THAI HOTEL AND HOSPITALITY MANAGEMENT ASSOCIATION SELECTS AVANI CIMCON
                  TECHNOLOGIES AS TECHNOLOGY PARTNER FOR TRAVEL PORTAL
                </h5>
                <p>
                  The Thai Hotel and Hospitality Management Association (THMA) announced today that
                  they have awarded Avani Cimcon Technologies the contract to utilize Avani Cimcon’s
                  travel solutions for their On-line Direct Travel & Booking Portal and as their
                  Travel Technology Consultants.
                </p>
                <p>
                  Customers will direct-book hotels, auto rentals, excursions and restaurants by
                  using the state of the art booking engine. The on-line portal will provide a user
                  friendly environment as well as rich and informing media provided by the
                  Associations 160+ members. As this is a direct booking model, with no middle man,
                  the cost savings are passed on to the consumer.
                </p>
                <p>
                  THMA is one of the most unique and progressive hotel entities in Thailand.
                  Established in 2004, the main objective of the association is to focus on the
                  marketing, training and development of small and medium size hotel entrepreneurs
                  in Thailand. Presently, THMA has 160+ hotels members all over Thailand, ranging
                  from 24 room resorts to 200 room properties. THMA aims to move forward to
                  strengthen the marketing network concept by establishing a Central Reservation
                  System under the name of Small Hotel Alliance Reservation Portal (SHARP) for added
                  global distribution.
                </p>
                <p>
                  Avani Cimcon Technologies. (avanicimcon.com) is global IT Company specializing in
                  Travel technology based in the United States. They bring 18+ years of software
                  development experience to this project.
                </p>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h5 className="text-primary mb-3">
                  THE ARUBA HOTEL AND TOURISM ASSOCIATION (AHATA) SELECTS AVANI CIMCON TECHNOLOGIES
                  AS TECHNOLOGY PARTNER FOR DESTINATION PORTAL
                </h5>
                <p>
                  The Aruba Hotel and Tourism Association (AHATA) announced today that they have
                  awarded Avani Cimcon Technologies the contract to build their next generation
                  destination portal.
                </p>
                <p>
                  The new destination portal will provide a complete experience allowing the user to
                  easily find, navigate and book their entire travel package with the association's
                  120+ members. Air, hotel, restaurants and excursions will be among the options
                  offered through the portal. In addition, the site will contain rich media and
                  social networking capabilities along with Association Member tools that will allow
                  for Customer Relationship Management and site specific destination analysis.
                </p>
                <p>
                  AHATA is one of the most unique and progressive hotel and tourism entities in the
                  Americas. For more than four decades it has made significant contributions to
                  Aruba's tourism industry by bringing together private sector ideas and funding in
                  support of the industry and community at large.
                </p>
                <p>
                  Avani Cimcon Technologies. (avanicimcon.com), based in Santa Rosa, CA, was
                  co-founded by Lomesh Shah and Saurabh Mehta. Avani Cimcon is a global, innovative
                  and leading edge IT company with clients in Australia, New Zealand, Greece and the
                  US. They bring 15 years of software development experience to this project, while
                  specializing in the travel, retail and financial services verticals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default News;
