import React from "react";
import { Link } from "react-router-dom";

const mystyle = {
  width: "48px",
  fontSize: "48px",
  marginBottom: "6px",
};

const buttonStyle = {
  position: "absolute",
  bottom: "15px",
  left: "34.5%",
};
const HomeServices = () => {
  return (
    <div className="home-services mb-4 mb-5">
      <div className="container">
        <h2 className="page-sub-title mb-4 text-center font-weight-bold">
          Wide Range of Solutions
        </h2>
        <div className="row">
          <div className="col-lg-3">
            <div className="box-color-1 shadow text-center p-3 rounded text-white">
              <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
              <h5 className="mb-3">Custom Solutions</h5>
              <p className="small">
                We provide a wide range of IT services such as web and mobile
                application development, e-commerce solutions, cloud services,
                UI/UX design, big data and analytics, data integration, ETL etc
              </p>
              <Link
                className="btn btn-sm btn-light"
                style={buttonStyle}
                to="/it-consulting-services"
              >
                Know More
              </Link>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="box-color-2 shadow text-center p-3 rounded text-white">
              <i className="fa fa-exchange" aria-hidden="true"></i>
              <h5 className="mb-3">API Management</h5>
              <p className="small">
                Seamless integration among cloud and on-premise applications for
                various industries including Insurance, Banking, Travel,
                Healthcare, Legal etc
              </p>
              <a
                className="btn btn-sm btn-light"
                href="http://dxchange.travelcarma.com"
                target="_blank"
                rel="noopener noreferrer"
                style={buttonStyle}
              >
                Know More
              </a>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="box-color-3 shadow text-center p-3 rounded text-white">
              <i className="fa fa-plane" aria-hidden="true"></i>
              <h5 className="mb-3">Travel & Hospitality</h5>
              <p className="small">
                End-to-end online solutions for travel and hospitality companies
                including reservation systems, back-office, inventory
                management, API integration, channel management
              </p>
              <a
                className="btn btn-sm btn-light"
                href="https://www.travelcarma.com"
                target="_blank"
                rel="noopener noreferrer"
                style={buttonStyle}
              >
                Know More
              </a>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="box-color-4 shadow text-center p-3 rounded text-white">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="kaaba"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                class="svg-inline--fa fa-kaaba fa-w-18 fa-7x"
                style={mystyle}
              >
                <path
                  fill="currentColor"
                  d="M554.12 83.51L318.36 4.93C308.51 1.64 298.25 0 288 0s-20.51 1.64-30.36 4.93L21.88 83.51A32.006 32.006 0 0 0 0 113.87v310.8c0 15 10.42 27.98 25.06 31.24l242.12 53.8c6.86 1.53 13.84 2.29 20.83 2.29s13.97-.76 20.83-2.29l242.12-53.8c14.64-3.25 25.06-16.24 25.06-31.24v-310.8c-.02-13.77-8.83-26-21.9-30.36zM528 248.87l-69.89-19.06c-5.09-1.39-10.11 2.44-10.11 7.72v16.58c0 3.61 2.41 6.77 5.89 7.72L528 282.04v129.8l-229.59 51.02c-4.1.91-11.66 2.03-20.83 0L48 411.84v-129.8l74.11-20.21a7.997 7.997 0 0 0 5.89-7.72v-16.58c0-5.28-5.02-9.11-10.11-7.72L48 248.87v-34.63l228.56-68.55c7.41-2.28 15.38-2.25 22.91 0L528 214.24v34.63zm0-84.74L313.31 99.72c-16.56-4.97-34.03-5-50.59 0L48 164.13V125.4l224.82-74.94c5.68-1.9 17.04-4.44 30.36 0L528 125.4v38.73zm-266.11 26.41l-96 26.18a7.997 7.997 0 0 0-5.89 7.72v16.58c0 5.28 5.02 9.11 10.11 7.72l96-26.18a7.997 7.997 0 0 0 5.89-7.72v-16.57c0-5.29-5.02-9.12-10.11-7.73zm48 32.01l96 26.18c5.09 1.39 10.11-2.44 10.11-7.72v-16.58c0-3.61-2.41-6.77-5.89-7.72l-96-26.18c-5.09-1.39-10.11 2.44-10.11 7.72v16.57a8 8 0 0 0 5.89 7.73z"
                  class=""
                ></path>
              </svg>
              <h5 className="mb-3">Umrah</h5>
              <p className="small">
                Off-the-shelf booking solution allowing pilgrims & B2B agents to
                book Umrah packages as well as flights, hotels, transportation
                and ground services online
              </p>
              <Link
                className="btn btn-sm btn-light"
                style={buttonStyle}
                to="/umrah-booking-solution"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
