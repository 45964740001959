import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/project-management.png";
import { Helmet } from "react-helmet";

class ProjectManagement extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Project Management, Practical Project management - avanicimcon.com</title>
          <meta
            name="keywords"
            content="Practical project management, Risk management projects, project management tools, project planning tool. Project planning and implementation, product management, software development, Project Management & SAP, offshore service, project planning and implementing, product management, software development and program management applications, practical project management."
          />
          <meta
            name="description"
            content="Project management is the key to a successful long distance relationship. One of the main apprehensions for an outsourcing organization is its inability to physically monitor and control offshore projects. Long lasting relationships are based on client's trust and their confidence in the ability of the offshore vendor to deliver as committed. Continuous comprehensive information feed to the client is an imperative that no offshore vendor can ignore."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Project Management</h1>
            <h5>Project Management</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Project management is the key to a successful long distance relationship. One of the
                main apprehensions for an outsourcing organization is its inability to physically
                monitor and control offshore projects. Long lasting relationships are based on
                client's trust and their confidence in the ability of the offshore vendor to deliver
                as committed. Continuous comprehensive information feed to the client is an
                imperative that no offshore vendor can ignore.
              </p>
              <p>
                Avani Cimcon Technologies (ACT) with its varied and long experience has evolved
                innovative, practical project management, communication protocols/standards and QA
                (Quality Assurance) processes customized for an offshore IT service business. These
                keep the client abreast of what's happening at the remote site on a daily basis
                without additional effort.
              </p>
              <p>
                Avani Cimcon Technologies understands that it is essential to keep the client
                informed of the progress of the project and convey this information to the client in
                an acceptable and understandable format. To do this Avani Cimcon Technologies takes
                the help of tools like a project-planning tool (e.g. MS project), standardized
                format reporting and a net-based information exchange and tracking system.
              </p>
              <p>
                Effective information sharing gives comfort to the client and builds their
                confidence and trust in the relationship. We clearly understand that only an in-time
                project implementation with the desired quality would allow the client to reap the
                benefits of going offshore.
              </p>
              <p>
                This section gives an idea of how the different stages in an offshore project are
                managed and reported.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectManagement;
