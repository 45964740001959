import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/objectives.png";
import { Helmet } from "react-helmet";

class Objective extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Objective and Goal Setting, Customer Satisfaction Objective, Company Objective
          </title>
          <meta
            name="keywords"
            content="It Services and software solutions, Objective and Goal Setting, Customer Satisfaction Objectives, Company Objective, Goal and Objectives, Corporate partners"
          />
          <meta
            name="description"
            content="At Avani Cimcon Technologies (ACT), partners work with us to deliver top-quality software products and IT services to help our customers move toward an exciting new way of doing business that lets companies anticipate customer needs, create opportunities and avoid potential problems. Partnering with innovative providers in their respective industries and areas of expertise allows us to offer outstanding value and a broader range of solutions on a global basis. Our partners are leaders in their fields."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Objective</h1>
            <h5>Objective</h5>
          </div>
          <img className="page-banner-img" src={BannerImg} alt="" />
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Partners are an important component of the Avani Cimcon Technologies’ business
                model. At Avani Cimcon Technologies (ACT), partners work with us to deliver
                top-quality software products and IT services to help our customers move toward an
                exciting new way of doing business that lets companies anticipate customer needs,
                create opportunities and avoid potential problems. Partnering with innovative
                providers in their respective industries and areas of expertise allows us to offer
                outstanding value and a broader range of solutions on a global basis. Our partners
                are leaders in their fields.
              </p>
              <div class="shadow-sm p-3">
                <ul>
                  <li className="mb-3">
                    To align our IT services and software solutions with the solutions offered by
                    the partners
                  </li>
                  <li className="mb-3">
                    To effectively support the partners in the sales process and post-sales support
                  </li>
                  <li className="mb-3">
                    To jointly market the solutions so as to increase the sales for the partner and
                    ACT
                  </li>
                  <li>
                    To provide committed resource, technical and infrastructure support for
                    cost-effective delivery of solutions to the customer
                  </li>
                </ul>
              </div>

              <h5 className="text-primary mt-4 mb-3">
                To compete in the global market, our partners can depend on Avani Cimcon
                Technologies for:
              </h5>
              <div class="shadow-sm p-3">
                <ul>
                  <li className="mb-3">Cost-effective solutions</li>
                  <li className="mb-3">Best quality and service in delivering the solution</li>
                  <li className="mb-3">Integrity and transparency</li>
                  <li className="mb-3">
                    Innovative solutions by blending technology and services{" "}
                  </li>
                  <li>Dedicated Offshore software Development Center in India</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Objective;
