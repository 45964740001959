import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/cms.png";
import { Helmet } from "react-helmet";
import CMSFlow from "../../assets/images/cms_flow.gif";

class CMS extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Content Management System (CMS), Content Management System Software, CMS</title>
          <meta
            name="keywords"
            content="Content Management System, CMS Lite, CMS Lite Software, CMS Social Networking, CMS enterprise, CMS BOX, cmsbox – content management system, box social networking solutions, white label social networking solution, out-of the box solution, network social solutions"
          />
          <meta
            name="description"
            content=" Content Management System (CMS) is a tool which helps non technical person to maintain and manage their website it is quick and easy to use like if you want to edit images, text, document, web pages etc, store them for reuse, and insert them on your website with no knowledge of HTML and without the help of webmasters or web developers. CMS Lite, CMS Social Networking, CMS Enterprise, (saas) Software as service"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">CMS</h1>
            <h5>CMS</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <h5 className="text-primary mb-3">
                CMS – A multilingual capable Content Management System for do-it-yourself web and
                social-networking portals
              </h5>
              <p>
                Avani Cimcon Technologies' Content Management System (CMS) is your chance to
                maintain your website and web content yourself, quickly and cost effectively. It
                works on the concept of "Do-It-Yourself", i.e. you can yourself create web content
                like images, text, document, web pages etc, store them for reuse, and insert them on
                your website with no knowledge of HTML and without the help of webmasters or web
                developers. It is available in three versions:
              </p>

              <ul className="mb-4">
                <li className="text-primary mb-2">
                  <b>CMS Lite</b>
                </li>
                <li className="text-primary mb-2">
                  <b>CMS Social Networking</b>
                </li>
                <li className="text-primary">
                  <b>CMS Enterprise</b>
                </li>
              </ul>

              <div className="shadow-md p-4">
                <div className="row">
                  <div className="col-lg-3 d-flex align-items-center">
                    <div>
                      <img src={CMSFlow} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <ul>
                      <li className="mb-2">Create and Launch Travel Portals/Websites Easily</li>
                      <li className="mb-2">
                        Do-It-Yourself: Update, reuse web content as and when needed
                      </li>
                      <li className="mb-2">WYSIWYG site builder</li>
                      <li className="mb-2">SEO friendly: Creates Search Engine Optimized pages</li>
                      <li className="mb-2">Easily submit your site to search engines</li>
                      <li className="mb-2">
                        Integration with Booking Engine and Dynamic Packaging
                      </li>
                      <li className="mb-2">
                        Domain Specific content: Host the same content with minor modifications for
                        multiple domains
                      </li>
                      <li className="mb-2">
                        Newer business modules, RSS etc., can be easily integrated
                      </li>
                      <li className="mb-2">
                        Content management allows sharing of content between multiple applications
                        like marketing automation suite
                      </li>
                      <li>
                        Linked contact management for storing of site visitors and their information
                        and preferences for marketing campaign.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <h5 className="text-primary mt-5">CMS Lite</h5>
              <p>
                CMS Lite is an ideal, out of the box content management solution specially packaged
                for small companies and professionals who want a presence on the Web and would want
                to update the content themselves.
              </p>

              <h5 className="text-primary mt-4">CMS Social Networking</h5>
              <p>
                Out of the box social networking solution allows implementation of current consumer
                trends like blog, broadcast, forums and other means of sharing information.
              </p>

              <h5 className="text-primary mt-4">CMS Enterprise</h5>
              <p>
                CMS Enterprise is a totally customizable solution designed for small to large
                enterprises to launch their websites as quickly and as cost efficiently as possible.
                An ideal implementation of this system would be the front-end system to a booking
                engine. The CMS can be used to update marketing content on a day-to-day basis or a
                weekly basis.
              </p>
              <p>
                Having a continuously updated website is a mandatory requirement to survive and grow
                in the dynamic highly competitive environment of the travel industry. But at the
                same time keeping content continuously updated is expensive and time consuming. CMS
                not only facilitates content management, but also gives you independent and full
                control of your website right from the simple ones to the most complicated.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CMS;
