import React from "react";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className="navbar">
      <ul className="row list-unstyled p-0 m-0">
        <li>
          <Link className="text-secondary" to="/">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="text-secondary">
            Company
          </Link>
          <ul className="row list-unstyled">
            <li>
              <Link to="/about">About us</Link>
            </li>

            <li>
              <Link to="/management">Management</Link>
            </li>

            <li>
              <Link to="/expertise-experience">Expertise & Experience</Link>
            </li>

            <li>
              <Link to="/advantage">ACT Advantage</Link>
            </li>

            <li>
              <Link to="/value-proposition">Value Proposition</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/dxchange" className="text-secondary">
            Products
          </Link>
          <ul className="row list-unstyled">
            <li>
              <Link to="/dxchange">DXchange</Link>
            </li>

            <li>
              <Link to="/travel-hospitality">Travel & Hospitality</Link>
            </li>

            <li>
              <Link to="/insurance">Insurance</Link>
            </li>

            <li>
              <Link to="/healthcare">Healthcare</Link>
            </li>

            <li>
              <Link to="/legal">Legal</Link>
            </li>

            <li>
              <Link to="/biz-rule-engine">BizRuleEngine</Link>
            </li>

            <li>
              <Link to="/cms">CMS</Link>
            </li>

            <li>
              <Link to="/umrah-booking-solution">Umrah Booking Solution</Link>
            </li>

            <li>
              <Link to="/more-products">More Products</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/it-consulting-services" className="text-secondary">
            Services
          </Link>
          <ul className="row list-unstyled">
            <li>
              <Link to="/it-consulting-services">IT Consulting Services</Link>
            </li>

            <li>
              <Link to="/design-suite">Design Suite</Link>
            </li>

            <li>
              <Link to="/project-management">Project Management</Link>
            </li>

            <li>
              <Link to="/microsoft-technologies">Microsoft Technologies</Link>
            </li>

            <li>
              <Link to="/dotnetnuke-development">DotNetNuke Development</Link>
            </li>

            <li>
              <Link to="/java-development">Java Development</Link>
            </li>

            <li>
              <Link to="/quality-assurance">Quality Assurance</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/international-acceptance" className="text-secondary">
            Customers
          </Link>
          <ul className="row list-unstyled">
            <li>
              <Link to="/international-acceptance">International Acceptance</Link>
            </li>

            <li>
              <Link to="/international-projects">International Projects</Link>
            </li>

            <li>
              <Link to="/domestic-projects">Domestic Projects</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/objective" className="text-secondary">
            Partners
          </Link>
          <ul className="row list-unstyled">
            <li>
              <Link to="/objective">Objective</Link>
            </li>

            <li>
              <Link to="/benefits">Benefits</Link>
            </li>

            <li>
              <Link to="/partners-program">Partners Program</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/news" className="text-secondary">
            News & Events
          </Link>
          <ul className="row list-unstyled">
            <li>
              <Link to="/news">News / Press Release</Link>
            </li>

            <li>
              <Link to="/events">Events</Link>
            </li>

            <li>
              <Link to="/membership">Membership</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/careers" className="text-secondary">
            Careers
          </Link>
        </li>
        <li>
          <Link to="/contact-us" className="text-secondary">
            Contact Us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
