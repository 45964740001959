import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/it-consulting.png";
import { Helmet } from "react-helmet";
import ServiceMatrix from "../../assets/images/act_services_matrix_center.png";

class ITConsultingServices extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            IT Consulting Services, Information Technology Consulting Services, IT consulting and
            Solutions - avaniCimcon.com
          </title>
          <meta
            name="keywords"
            content="IT consulting services, information technology consulting services, IT consulting and solutions. IT consulting services, it consultant, IT services company, IT solutions company, Total IT solutions, IT Business Consulting Services, Consulting Services, IT services, IT consulting company, IT consultants and technology consulting, IT consulting offshore application custom software development, It consulting services, IT consulting and solutions, software consulting services, software development and consulting IT services, offshore development services, It consulting offshore application custom software development"
          />
          <meta
            name="description"
            content="We are concentrating on Internet enabled business information systems in a wide range of industries by providing IT Consultant Services, India - offers Quality IT Services and IT Solutions, Professional Enterprise IT Consulting Services, Our team includes persons with experience in a broad range of technologies and expertise in all segments of solution and development."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">IT Consulting Services</h1>
            <h5>IT Consulting Services</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies is an IS/ISO 9001:2008 company, with more than 15 years of
                experience in providing software development and consulting IT services to our
                international clients. We utilize proven technologies to enable customer's business
                systems. This technology focus has given us the opportunity to become proficient on
                a wide variety of industry leading tools as well as gaining experience with most
                database platforms. We are concentrating on Internet enabled business information
                systems in a wide range of industries.
              </p>
              <p>
                Our Onsite and offshore development capabilities helps to create the framework for
                our client’s technology infrastructure. At the core of our strategy is our belief in
                a long-term relationship and commitment to the customer. The major aim of our
                operation model is in delivering strategic value through operational efficiency,
                cost savings and training - resulting in a higher yield per dollar spent thus
                enabling our clients to build a bigger and more competitive business.
              </p>
              <p>
                Our quality assurance processes, customized for an offshore business model, allows
                us to provide our clients with work of a consistent measurable quality. Efficient
                planning and communication with regular reporting and documentation allows
                monitoring of projects by clients on a daily basis. We have the experience, the
                requisite skills, and the resources along with standardized well-documented
                processes that help us deliver projects on time and within budgets.
              </p>

              <h5 className="text-primary mt-4 mb-3">
                Our sphere of services encompasses every stage of the Software Development Life
                Cycle:
              </h5>

              <ul>
                <li className="mb-2">Requirements analysis and documentation services</li>
                <li className="mb-2">System design services</li>
                <li className="mb-2">Product and web design services</li>
                <li className="mb-2">Development and programming services</li>
                <li className="mb-2">DBA services</li>
                <li className="mb-2">QA planning and testing services</li>
                <li className="mb-2">
                  System administrator, implementation and maintenance services
                </li>
                <li>Porting services</li>
              </ul>

              <div className="mt-4">
                <img src={ServiceMatrix} alt="" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ITConsultingServices;
