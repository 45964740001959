import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/quality-assurance.png";
import { Helmet } from "react-helmet";

class QualityAssurance extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Software Development Quality Assurance, Software Quality Assurance Specialist, Quality
            Assurance In Software Development - avanicomcon.com
          </title>
          <meta
            name="keywords"
            content="quality assurance and software, quality assurance for software, quality assurance in software, quality assurance in software development, software development quality assurance, software quality assurance, software quality assurance company, software quality assurance consulting, software quality assurance India, software quality assurance management, software quality assurance services, software quality assurance specialist, software quality assurance team, software testing & quality assurance, software testing and quality assurance, software quality assurance"
          />
          <meta
            name="description"
            content="Avani Cimcon Technologies (ACT) offers software quality assurance services. Hire Software quality assurance specialist from India at reasonable cost"
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Quality Assurance</h1>
            <h5>Quality Assurance</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                At Avani Cimcon Technologies (ACT), our mission is to provide quality services and
                ensure total customer satisfaction through continual upgrade of systems, processes
                and people. Our well-defined processes, complete and thorough documentation, proper
                configuration management, periodic compliance reviews and audits assure that Quality
                Assurance at ACT is always above industry standards.
              </p>

              <p>For Quality Assurance we are provide:</p>

              <div className="row">
                <div className="col-lg-6 d-flex">
                  <div className="shadow-sm p-3">
                    <h4 className="text-primary mb-3 font-weight-bold">Automated Testing</h4>

                    <p>
                      Importance of automated testing increases when deadlines are fast approaching.
                      Code review, load testing, regression testing are some of the key areas that
                      can be handled efficiently using automated testing tools. We have developed
                      AUTO TEST, our own testing framework, which easily integrates with testing
                      tools extending their functionality. We are the partners of AutomatedQA for
                      the test complete training. For Automated Testing we are specialize in the
                      following testing tools:
                    </p>

                    <ul>
                      <li className="mb-3">WinRunner</li>
                      <li className="mb-3">Rational Robot</li>
                      <li className="mb-3">TestComplete</li>
                      <li className="mb-3">QuickTest Pro</li>
                      <li className="mb-3">LoadRunner</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 d-flex">
                  <div className="shadow-sm p-3">
                    <h4 className="text-primary mb-3 font-weight-bold">Manual Testing</h4>

                    <p>
                      We also provide manual testing facility to our clients. Our manual testing
                      schedule is based on a test plan. Test case preparation starts along with
                      coding. Bug Tracker is used to keep a track of bugs\errors encountered during
                      manual testing. Bug reports are generated at the end of the testing phase.
                      Quality can be assured only if controlled. Information, changes and bugs need
                      to be tracked as these are important factors that can affect quality. So for
                      quality assurance we are providing the collaboration tools listed below:
                    </p>

                    <ul>
                      <li className="mb-3">Bug Tracker</li>
                      <li className="mb-3">BOM Generator</li>
                      <li className="mb-3">Change Management System</li>
                      <li className="mb-3">i-Trak</li>
                      <li className="mb-3">ACTL DB Manager</li>
                      <li className="mb-3">Upload Track Synchronize</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default QualityAssurance;
