import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/biz-rule-engine.png";
import { Helmet } from "react-helmet";

class BizRuleEngine extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Business Rule Engine, Rules Engine â€“ Avanicimcon.com</title>
          <meta
            name="keywords"
            content="Biz Rule Engine, Biz Rules Engine: Business Rules Engine, Business Rules Management System (BRMS), Biz Rules Engine, Business Rules Engine, biztalk business rules engine, Business Rule Engine, Rules Engine, Open-source business rules engine, Biztalk business rules engine, business rule engine, business rules, SOA services, Soa Services oriented architecture, BPM/BRE Rule Engines, Biz Rules, Rules engine api,"
          />
          <meta
            name="description"
            content="Business Rules Engine presents architecturally with no trouble plug gable solution with significance added features focused on segregate business rules from client application, and appropriate to wide variety of application domains. Split rules engines permits rules to be coded, reused and scaled as needed. Out of the regular applications and put it in other resources such as DATABASE, XML, Java, HTML or DLL/jar rules engine"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">BizRuleEngine</h1>
            <h5>BizRuleEngine</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Business Rules Engine is to offer architecturally easily pluggable solution with
                value added features focused on segregating "business rules" from client
                application, and applicable to wide variety of application domains. Business Rules
                Engine is essentially a set of tools that allows organizations to build calculations
                and decision logics that operate according to company's data.
              </p>
              <p>
                Business Rules Engine empowers business experts to change business rules dynamically
                without any intervention with application developers, thus reducing Turnaround Time
                in applying business rules. Basic concept of business rules engine is to separate
                the business logic from the system logic allowing substantial changes in system
                behavior without substantial changes in code.
              </p>
              <p>
                Some of the very large-scale web applications like Insurance applications, Banking
                solutions, etc. have their work flow defined in such a way that there are times when
                some of the logic, at any part of the flow, requires some modifications. It will be
                expensive and time-consuming process to modify the logic while defining some
                business rules now and then. All these comes to a conclusion to find a way to
                separate out the business rules, out of the regular applications and put it in other
                resources such as DATABASE, XML or DLL/jar.
              </p>
              <p>
                For more details please visit{" "}
                <a
                  href="http://www.bizruleengine.com"
                  target="_blank"
                  alt=""
                  rel="noopener noreferrer"
                  className="text-primary"
                >
                  http://www.bizruleengine.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BizRuleEngine;
