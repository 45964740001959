import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/act-advantage.png";
import { Helmet } from "react-helmet";

class Advantage extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Software Solution Providers, Software Solution India, Offshore Software Development,
            Advantage – avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Software Solutions Team, Relationship management expertise, Quality Assurance (Testing), offshore software development, product development, customized offshore operations, Total programming service, programming service"
          />
          <meta
            name="description"
            content="We are Web solutions provider Software Apps Development Company with broad range of support services such as design services, documentation services, DBA services, systems services, QA services, etc. Avani Cimcon Technologies (ACT) have effortlessly incorporated years of experience with fast moving ahead technology and have increasingly added new competencies to our catalog of skills and knowledge."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">ACT Advantage</h1>
            <h5>ACT Advantage</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Outsourcing is a popular strategy used by businesses worldwide to gain a competitive
                edge. This requires efficient delivery mechanisms and use of cutting edge
                technologies. At Avani Cimcon Technologies (ACT), we have seamlessly integrated
                years of experience with rapidly progressing technology and have progressively added
                new competencies to our repertoire of skills and knowledge.
              </p>

              <div className="shadow-sm p-3 mt-2">
                <h4 className="text-primary mb-3 font-weight-bold">
                  Packaged Services, A Concept from ACT
                </h4>

                <p>
                  It has been our experience that programming services especially in the 'Offshore'
                  scenario requires support from ancillary resources to give a complete service
                  experience. “Packaged Services” is conceptualized as a 'Total programming
                  service'. The following is provided within the cost per resource.
                </p>

                <ul>
                  <li className="mb-3">
                    A broad range of support services such as design services, documentation
                    services, DBA services, systems services, QA services , etc.
                  </li>
                  <li className="mb-3">
                    'Internal consultants group' - This group consists of senior employees who work
                    as internal consultants and advise the project teams on technology and
                    processes.
                  </li>
                  <li className="mb-3">
                    We provide clients with development frameworks and libraries that allow for
                    standardization of programming methodologies that reduce maintenance costs and
                    inhibit the occurrence of errors.
                  </li>
                  <li>
                    Over the years we have developed a number of collaboration tools/software and
                    utilities that help in rapid application development and relationship
                    management.
                  </li>
                </ul>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h4 className="text-primary mb-3 font-weight-bold">Our Experience</h4>

                <p>
                  With 15+ years of offshore software development and consulting experience we have
                  gained knowledge in systems design, product development, and project management
                  and most importantly, how to make remote relationships work.
                </p>

                <ul>
                  <li className="mb-3">
                    Over time and through continuous upgrade of systems, processes and people, we
                    have set up a sustainable operations model customized for offshore operations.
                  </li>
                  <li className="mb-3">
                    A pool of talented and experienced resources with proven experience of doing
                    business offshore. Our technical skills can be compared with the best in the
                    world.
                  </li>
                  <li className="mb-3">
                    Certified Quality Assurance (Testing) processes that have been customized for an
                    offshore outsourcing business model.
                  </li>
                  <li className="mb-3">
                    Efficient planning and communication with regular reporting and documentation
                    allows for in-depth monitoring of projects.
                  </li>
                  <li>
                    Relationship management expertise acquired as a result of our interactions with
                    our worldwide client set. This expertise gives us an edge in creating a
                    successful relationship.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Advantage;
