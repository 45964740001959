import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/membership.png";
import { Helmet } from "react-helmet";

import logo1 from "../../assets/images/membership/logo_microsoft.gif";
import logo2 from "../../assets/images/membership/logo_intel.bmp";
import logo3 from "../../assets/images/membership/logo_qa.gif";
import logo4 from "../../assets/images/membership/logo_naskom.gif";
import logo5 from "../../assets/images/membership/logo_elance.gif";
import logo6 from "../../assets/images/membership/logo_ahk.gif";
import logo7 from "../../assets/images/membership/logo_indoame.gif";
import logo8 from "../../assets/images/membership/logo_cii.gif";
import logo9 from "../../assets/images/membership/logo_gesia.jpg";
import logo10 from "../../assets/images/membership/logo_caribbean-hotel-tour-associati.gif";

class Membership extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Membership | Avani Cimcon Technologies Ltd</title>
          <meta name="keywords" content="" />
          <meta name="description" content="" />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Membership</h1>
            <h5>Membership</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <ul className="row list-unstyled p-0 m-0">
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo1} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo2} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo3} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo4} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo5} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo6} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo7} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo8} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo9} alt="" />
                </div>
              </li>
              <li className="col-lg-3 d-flex w-100">
                <div className="d-flex w-100 rounded shadow-md mb-4 align-items-center d-flex justify-content-center">
                  <img src={logo10} alt="" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Membership;
