import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/actl_logo.gif";
import Menu from "./menu";

const Header = () => {
  return (
    <header className="d-flex align-items-center shadow-sm">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/" className="text-secondary">
              <img src={Logo} alt="avani cimcon technologies" />
            </Link>
          </div>
          <div className="col-lg-9 d-flex justify-content-end">
            <Menu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
