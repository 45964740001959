import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/cms.png";
import { Helmet } from "react-helmet";

class Carma extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>CARMA</title>
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">CARMA</h1>
            <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies is promoted and managed by highly skilled and experienced
                technocrats. The Managing Director is a technology strategist, agile manager and
                entrepreneurial innovator skilled at managing international relationships, with 20
                years of IT outsourcing industry experience. He has a postgraduate in Sciences from
                the US and has traveled extensively over the world. He has extensive experience in
                Designing, Programming and Implementation of Information and Automation Systems for
                a variety of businesses. Additionally he has extensive experience of facing
                customers of all kinds and marketing services in different countries. He is the
                Chief Architect in the organization and provides the vision and leadership. He is on
                the advisory board of a number of companies as a consultant and is involved in
                framing their Business and Information System strategies.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Carma;
