import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/value-proposition.png";
import { Helmet } from "react-helmet";

class ValueProposition extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Customer Value Proposition, Value Proposition, Business Valuation Model -
            Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Customer Value Proposition, Unique Value Proposition, Value Proposition, Online Value Proposition, client Value proposition, consumer value proposition, Solutions built on proprietary SOA, soa architecture, service-oriented architecture, Offshore development, Solutions already deployed and client-accepted, deployed solutions"
          />
          <meta
            name="description"
            content="We take extensive care for our customers regarding business model and their value propositions providing them Solutions built on proprietary SOA, which has been developed ground-up, Modular, and configurable solutions customized for industry verticals like Travel and BFSI, Quality focus (IS/ISO 9001:2008 certified), going onto to being CMM-SEI certified and Offshore development, support and implementation makes offerings cost effective"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Value Proposition</h1>
            <h5>Value Proposition</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <ul className="list-unstyled p-0 m-0">
                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Cutting-edge solutions with huge market potential
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Modular and configurable solutions customized for industry verticals like Travel
                    and BFSI
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Solutions built on proprietary SOA which has been developed ground-up
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Applications designed around the solutions provide additional value
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Solutions already deployed and client-accepted
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Unique marketing propositions along with experience and expertise in the
                    verticals leads to faster ROI and turnaround times for the customers
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Proven execution capabilities with a strong technology focus
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Ongoing investments in solutions for upgradation and research
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Quality focus (IS/ISO 9001:2008 certified), going onto to being CMM-SEI
                    certified
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Cutting-edge solutions with huge market potential
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Process driven with Effective and Efficient delivery mechanisms
                  </div>
                </li>

                <li className="mb-3">
                  <div className="d-flex shadow-sm p-2 rounded align-items-center">
                    <i className="fa fa-check-circle mr-2 text-primary" aria-hidden="true"></i>
                    Offshore development, support and implementation makes offerings cost effective
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ValueProposition;
