import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/about-us.png";
import { Helmet } from "react-helmet";

class About extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            IT software solutions, Software Solution Services, Online Booking Management System,
            Software, Solutions Services - Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content=" Web Solutions provider, Online Software, Software development consulting services, Software development services, software solutions, software development, Online software, custom software development, IT services, custom software development services, software solution customization, custom web programming services, IT software solution, software development company India, software development professional, software development services, software product development company, software development India, Travel Solutions, Insurance Solutions, Financial Services, Travel solution provider, online travel solutions, Online booking software, Booking Engines, content management systems, Software Development Consultancy, Customized Content Management system, content management, booking management, online solution provider, Reservation Management Systems, Booking Systems, Booking Software, Reservation Software, Reservation Management Software, Management Software, Facilities Bookings, Destination Inventory Management System and Online Travel Portal"
          />
          <meta
            name="description"
            content="Our company is an IS/ISO 9001:2008 Organization with strong experience of 15 years in IT software solution, custom software development services, software development, consulting and IT services contributing in the growth of business. We have enormous clientele spread internationally using our software solution and services for their risk management business necessities. We have made our tough occurrence in USA, UK, Asia Pacific, Australia, New Zealand, Middle East and European Unions."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">About us</h1>
            <h5>About us</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies (ACT) offers software solutions, software development,
                consulting and IT services to clients globally-as partners to conceptualize and
                realize technology driven business transformation initiatives. With a strong
                development team and expertise on latest technologies, we always focus on the pain
                areas of our clients and try to help them with cost-effective software solution and
                IT services.
              </p>
              <p>
                We are an IS/ISO 9001:2008 Organization with strong experience of 15 years in IT
                software solution and services offering business. We have huge clientele spread
                across the global boundaries using our software solution and services for their
                mission-critical business requirements. We have made our strong presence in USA, UK,
                Asia Pacific, Australia, New Zealand, Middle East and European Unions.
              </p>
              <p>
                Our mission is "To deliver strategic value through consistent delivery of Technology
                Solutions, at an extremely Competitive Price, resulting in Comprehensive Business
                Results."
              </p>
              <p>
                Innovation is an important part of our growth strategies and has emerged with an
                Intellectual Property. It is an implied promise to our customers and prospects that
                we are striving constantly to provide with all new technological possibilities
                helping them achieve their business objective. We feel proud to be short-listed to
                be profiled in the prestigious listing of NASSCOM’s 100 IT Innovators – 2007.
                www.nasscom.in/100itinnovators
              </p>
            </div>

            <h5 className="text-primary mt-4 mb-3">Some of our Key Milestones are:</h5>
            <table className="table table-bordered">
              <thead>
                <tr className="bg-light">
                  <th>Year</th>
                  <th>Milestones</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>1991</td>
                  <td>Memory driven simple accounting system</td>
                </tr>
                <tr>
                  <td>1991</td>
                  <td>ERP system for Transport Sector and Textile Process houses</td>
                </tr>
                <tr>
                  <td>1995</td>
                  <td>First offshore project done completely via CompuServe and Internet</td>
                </tr>
                <tr>
                  <td>1996</td>
                  <td>Changed Business Model to 100% Offshore</td>
                </tr>
                <tr>
                  <td>2000</td>
                  <td>
                    AI Expert System for Healthcare Industry in six different international
                    languages
                  </td>
                </tr>
                <tr>
                  <td>2001</td>
                  <td>
                    Developed a full rendering engine technology, which allows for fully controlled
                    rendering on any browser. Works in ASP, .Net, J2EE
                    <br />
                    Database driven Work Flow engine with Business Rules.
                    <br />
                    First use of XML HTTP (now known as AJAX) in websites
                  </td>
                </tr>
                <tr>
                  <td>2002</td>
                  <td>Designed a fully AJAX driven Internet based WYSIWYG Website Wizard in ASP</td>
                </tr>
                <tr>
                  <td>2003</td>
                  <td>
                    Implemented self-service Agent Portal for the Independent Agent Distribution
                    Channel
                  </td>
                </tr>
                <tr>
                  <td>2005</td>
                  <td>
                    Introduced Database driven CMS portal
                    <br />
                    Released AJAX event driven platform agnostic RAD Application architecture for
                    multi-lingual Internet applications and used in multiple projects
                    <br />
                    Implemented LCS (Licensing & Contracting System) for the Insurance vertical
                  </td>
                </tr>
                <tr>
                  <td>2006</td>
                  <td>
                    Architected & Implemented NEBUPRO (New Business Processing)
                    <br />
                    First version of DXchange
                  </td>
                </tr>
                <tr>
                  <td>2007</td>
                  <td>
                    Introduced Portal Builder Solution for Destination / Associations / Tourism
                    Boards
                    <br />
                    Introduced Online Bus Reservation System with integrated Agents Commission
                    management
                    <br />
                    Authored a SOA architecture based fully flexible ESB with an integrated
                    web-interface rule engine for which we were Recognized as "Top 100 IT Innovator"
                    in India by NASSCOM
                  </td>
                </tr>
                <tr>
                  <td>2008</td>
                  <td>
                    Introduced Booking Engines & Central Reservation Systems for Travel industry
                    <br />
                    Content Management systems for online portals
                  </td>
                </tr>
                <tr>
                  <td>2009</td>
                  <td>
                    Version-2 of DXchange with GUI driven Business Rules Engine for the travel
                    industry
                    <br />
                    NetPhantomDX middleware solution for BFSI industry with GUI editor
                    <br />
                    Introduced B2B, B2C, B2B2C Online Travel Portal solutions
                    <br />
                    Invited to bid for Portal Builder by a USD 28B enterprise Travel Company
                    <br />
                    Our major bus solutions customer in India was able to effect a selloff of the
                    business
                  </td>
                </tr>
                <tr>
                  <td>2010</td>
                  <td>
                    Introduced Solutions Package for B2B Travel Aggregator – includes solutions like
                    CMS, Booking Engine, CRS, XML platform and Agents Management.
                    <br />
                    Introduced n-level hierarchical Agency Management System with Commissions
                    management
                  </td>
                </tr>
                <tr>
                  <td>2011</td>
                  <td>
                    Development and Implementation of the Travel Cloud
                    <br />
                    Awarded as "Top 8 SaaS-Apps" by NASSCOM
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
