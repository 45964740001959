import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/expertise-experience.png";
import { Helmet } from "react-helmet";

class ExpertiseExperience extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Expertise &amp; Experience, Skilled Team, Software Professionals, Project
            Managers/System Architects - avanicimcon.com
          </title>
          <meta
            name="description"
            content=" We have a skilled team of experienced, techno-savvy software qualified with long-term domain expertise, System administrators for implementation and remote server management with Application Expertise, Microsoft Technologies, Java Technologies, Web Technologies, Development Tools, Testing Tools, QA, Testing, System Analyst, Team Leader, Project Manager for Custom Software Development India."
          />
          <meta
            name="keywords"
            content=" Expertise & Experience, Project Managers/System Architects, Business analysts, Application Expertise, insurance industry, failure analysis, consultant, consultants, consulting, expert, experts, expertise, skilled team, software professionals, Project Managers/System Architects, Business analysts, Business and Technology Consulting Solutions, Managed Business Systems, Expert Consultants, Expert Witness, Engineering Experts, expertise compatible, expertise & experience, expert Embedded System software development, Application Expertise, Development and programming services, Avani Cimcon Technologies Ltd India "
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Expertise & Experience</h1>
            <h5>Expertise & Experience</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <div className="shadow-sm p-3">
                <h4 className="text-primary mb-3 font-weight-bold">Our People:</h4>

                <p>
                  Avani Cimcon Technologies is comprised of a skilled team of experienced,
                  techno-savvy software professionals with long-term domain expertise. They
                  effectively add value at every stage of the Software Development Life Cycle. From
                  a ‘resource functionality’ perspective we provide our customers with the
                  following:
                </p>

                <ul>
                  <li className="mb-3">
                    Business analysts
                    <ul>
                      <li>Specification and requirements documentation</li>
                    </ul>
                  </li>
                  <li className="mb-3">
                    QA resources
                    <ul>
                      <li>Expertise to develop and implement testing strategies</li>
                    </ul>
                  </li>
                  <li className="mb-3">Programming resources</li>
                  <li className="mb-3">DBA resources for database management</li>
                  <li className="mb-3">
                    System administrators for implementation and remote server management
                  </li>
                  <li>Project Managers/System Architects</li>
                </ul>
                <p>
                  As we have a significant international reach we’ve learned how to work with many
                  groups from different cultures and different regions of the world. Thus, we enjoy
                  rewarding relationships based on mutual trust and earned professional respect.{" "}
                </p>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h4 className="text-primary mb-3 font-weight-bold">Our Services:</h4>

                <p>
                  Our sphere of services encompasses every stage of the Software Development Life
                  Cycle:
                </p>

                <ul>
                  <li className="mb-3">Requirements analysis and documentation services</li>
                  <li className="mb-3">System design services</li>
                  <li className="mb-3">Product and web design services</li>
                  <li className="mb-3">Development and programming services</li>
                  <li className="mb-3">DBA services</li>
                  <li className="mb-3">QA planning and testing services</li>
                  <li className="mb-3">
                    System administration, implementation and maintenance services
                  </li>
                  <li>Porting services</li>
                </ul>
              </div>
            </div>

            <table className="table table-bordered mt-4">
              <tbody>
                <tr>
                  <th className="bg-light text-nowrap">Application Expertise</th>
                  <td>
                    N-tier Application, Internet / Ecommerce Applications, Rational Unified Process,
                    Microsoft Solutions Framework, DotNetNuke, Kentico
                  </td>
                </tr>

                <tr>
                  <th className="bg-light text-nowrap">Microsoft Technologies</th>
                  <td>.NET, IIS, COM, DCOM, MSXML, Remoting, ASP.NET</td>
                </tr>

                <tr>
                  <th className="bg-light">Java Technologies</th>
                  <td>J2EE, Struts, Weblogic, WebSphere, EJB</td>
                </tr>

                <tr>
                  <th className="bg-light">Web Technologies</th>
                  <td>WAP, XML, HTML, HTTP, XHTML, XSL XML-FO</td>
                </tr>

                <tr>
                  <th className="bg-light">Development Tools</th>
                  <td>MS SQL Server, Oracle, MySQL, Data Modeling Tools</td>
                </tr>

                <tr>
                  <th className="bg-light">Testing Tools</th>
                  <td>
                    TestComplete, WinRunner, QuickTestPro, Rational Suite of testing tools,
                    LoadRunner, MS Web Application Stress Tool, Link Checkers and HTML Validators
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ExpertiseExperience;
