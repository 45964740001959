import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/legal.png";
import { Helmet } from "react-helmet";
import LawFirmnetWork from "../../assets/images/Lawfirmnetwork.jpg";

class Legal extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Legal Document Management System, Attorney Software, Case Management System, Law
            Practice Management Software - avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Law Practice Management Software, Document Management, Attorney Software, Case management, Legal Case Management Software, Legal Case Management Software, Law Office Management Software, Law Firm Financial Management Software, Law Firm Accounting Software,   Legal Calendar Software, Law Practice Management Software, legal document management systems, document management systems law firms, Legal Solutions Suite, Document Management System, legal document systems, document management software,  Legal Time and Billing Software, Data Exchange and Transaction Processing modules"
          />
          <meta
            name="description"
            content="We have urbanized a collection of solutions, which we jointly term as â€œLaw firm solution suiteâ€. We have Data Exchange and Transaction Processing modules, Case Management System (CMS) that includes features like Dashboard, Workflow, Work list, Event and documents. Our legal document management software solution enables law firms to effortlessly work together with many vendor systems and services straight or via integration with third party products or middleware solutions"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Solutions for Law Firms</h1>
            <h5>Solutions for Law Firms</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                At Avani Cimcon Technologies, we have significant experience of Legal domain. With
                dynamically changing business needs, increased competition and costs, the Law firms
                seeks new and innovative methods to manage their business operations. We understand
                the criticality of business and support our clients with innovative solutions and
                expert services. We have been working with leading law firms of US and providing
                them innovative solutions to effectively manage their operations.
              </p>
              <p>
                We have developed an array of solutions which we collectively call as "Law firm
                solution suite". This suite of solutions enables small to large law firms that do
                not have in-house Information Technology, to easily interact with many vendor
                systems and servicers directly or via integration with third party products or
                middleware solutions.
              </p>
            </div>

            <div className="mt-4">
              <img src={LawFirmnetWork} alt="" />
            </div>

            <div className="shadow-sm p-3 mt-4">
              <h4 className="text-primary mb-3 font-weight-bold">
                Major components of this system include:
              </h4>
              <ul>
                <li>
                  <b className="text-primary">Case Management System (CMS)</b>
                  <p>
                    A complete module to manage cases like foreclosure, Bankruptcy, Eviction,
                    Litigation, Monitor, Reforeclosure, and Deed in Lieu.
                  </p>
                </li>

                <li>
                  <b className="text-primary">Document Management System</b>
                  <p>
                    This module allows managing documents in an organized way based on different
                    matter types (Foreclosure, Bankruptcy, Litigation, etc.) and year wise.
                  </p>
                </li>

                <li>
                  <b className="text-primary">Data Exchange and Transaction Processing modules</b>
                  <p>
                    SOA (Service Oriented Architecture) based middleware solution for exchange of
                    case related data with different Vendor systems or Servicers, like Provest,
                    RedVision, NewTrak, Countrywide, ASAP Legal etc. via NetDirector and also direct
                    integration with USA Foreclosure, REO Experts, etc. Module also processes data
                    received from Servicers and updates CMS database accordingly.
                  </p>
                </li>

                <li>
                  <b className="text-primary">Accounting and Billing Interface</b>
                  <p>
                    Integration with client's accounting system and module to transfer invoices from
                    client’s system to the third party invoice processing systems like NewInvoice
                    (NewTrak) and iClear (VendorScape).
                  </p>
                </li>
                <li>
                  <b className="text-primary">Reporting System and others.</b>
                </li>
              </ul>
            </div>

            <div className="shadow-sm p-3 mt-4">
              <h5 className="text-primary mb-2">Case Management System (CMS )</h5>
              <p>
                The case management system (CMS) is the central system for law firms’ internal users
                to manage case details. The CMS manages the entire foreclosure process effectively
                to reduce cost and increase efficiency. The CMS also handles cases of other matter
                types like Bankruptcy, Eviction, Litigation, Monitor, Reforeclosure, and Deed in
                Lieu.
              </p>
              <p>Some of the important modules and features of the CMS are:</p>
              <ul>
                <li className="mb-2">
                  <b>Dashboard:</b> Provide reports for different departments in the law firm. E.g.
                  First Legal, Post Judgment, Monitor, Payoff, etc.
                </li>
                <li className="mb-2">
                  <b>Workflow:</b> The entire process of handling a case is encapsulated in a case
                  process workflow. Once that is setup, clerical staff with limited experience can
                  effectively follow the correct steps of a complex legal process.{" "}
                </li>
                <li className="mb-2">
                  <b>Worklist:</b> Worklist items assigned to different Processors can be shown
                  based on different categories like Foreclosure Hearing, Gap Search Due, Title
                  Claim Filed etc. System provides different filter criteria based on category
                  selected.
                </li>
                <li className="mb-2">
                  <b>Event:</b> Set of events is associated with the cases. For example, File
                  Received, File Sent to Search, Complaint Filed, Service Complete, Judgment
                  Entered, Sale Held, File Closed, etc.
                </li>
                <li className="mb-2">
                  <b>Documents:</b> Documents are stored into document repositories, and associated
                  with a particular case. The Document Management Service will move documents placed
                  on internal users folders as well received from outside interfaces into specified
                  folder structure based on the business rules defined.
                </li>
                <li>
                  Other modules are also part of CMS like Exception, Loan, Parties, Notes, Quick
                  Notes, Delays/ Holds, Calendar, Hearings, Sales etc.
                </li>
              </ul>
            </div>

            <div className="shadow-sm p-3 mt-4">
              <h5 className="text-primary mb-2">Document Management System</h5>
              <p>
                This system allows managing documents in an organized way based on different matter
                types (Foreclosure, Bankruptcy, Litigation, etc.) and year wise. Documents sent by
                Servicers for different transactions are associated/ linked with the case/ loan and
                shown in CMS (Case Management System) when file/ loan are reviewed.
              </p>
              <p>The processing and features of Document management system are as below:</p>

              <ul>
                <li className="mb-2">
                  Manage documents based on different matter types (Foreclosure, Bankruptcy,
                  Litigation, etc.) and year wise.
                </li>
                <li className="mb-2">
                  Documents can be received from different vendor systems or can be created by
                  internal users/ Attorneys.
                </li>
                <li className="mb-2">
                  Based on the law firm requirement, Document Repository structure can be defined.
                </li>
                <li className="mb-2">
                  Document Service runs at scheduled time to process documents. Documents will be
                  moved to Target location based on the rules defined.
                </li>
                <li>
                  Email notification will be sent to appropriate user if the Document name is not
                  valid and provide details about what is incorrect and guideline to correct
                  document name.
                </li>
              </ul>
            </div>

            <div className="shadow-sm p-3 mt-4">
              <h5 className="text-primary mb-2">
                Data Exchange and Transaction Processing modules
              </h5>
              <p>
                Our SOA (Service Oriented Architecture) based middleware solution and associated
                services help law firms to exchange their case management system data with different
                vendors, servicers and trading partners in a seamless bi-directional, real-time
                manner.
              </p>
              <p>
                Overcoming the challenges of data exchange with vendors, like mapping of case
                management data with their data elements, configuration, writing schedules/Windows
                services to send data, building Web services, etc., we have successfully integrated
                with Data Exchange providers like NetDirector, CaseAware, and USA Foreclosure.
              </p>
            </div>

            <div className="shadow-sm p-3 mt-4">
              <h5 className="text-primary mb-2">Accounting and Billing Interface</h5>
              <p>
                The accounting interface is integrated with third party accounting system named
                ‘Exact Globe”, which enables maintaining accounts and other billing / payment
                related details. This enables users of law firms to efficiently manage the
                processing of invoices in bulk and eliminating redundant data entries.
              </p>
              <p>
                The Billing interface is integrated with third party invoice processing systems like
                NewInvoice (NewTrak), CaseAware and iClear (VendorScape). On the other side, the
                billing interface is integrated with client’s accounting system. This enables the
                users to fetch the invoices from accounting system and send it to invoice processing
                system and effectively manage the entire process.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Legal;
