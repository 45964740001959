import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/insurance.png";
import { Helmet } from "react-helmet";

class Insurance extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Insurance Industry Solutions, Insurance Management System, Insurance Management
            Software, Insurance Solutions â€“ avanicimcon.com
          </title>
          <meta
            name="description"
            content=" We provide agile core insurance solutions through its insurance management services of effective, regulating, mechanized, and outsourcing insurance business procedures and the infrastructure that supports them. Avani Cimcon Technologies Ltd specializes in web based insurance management software system like insurance Policy Administration, agency and agent management system software, insurance solution for insurers and brokers - continuous upgrade and maintenance, Licensing and Contracting solutions, life and non life insurance business management. We have flawless online insurance management software for your insurance business solution. Policy Administration System Online Insurance Agency Management Solution Web Based Applications, We have worked with our clients on the following Agency Solutions and E-commerce Solutions specific for Insurance domain. "
          />
          <meta
            name="keywords"
            content=" insurance management software, insurance crm software, insurance, SOX compliant Document Management System, insurance policy management systems, Insurance Software, insurance policy system, insurance policy administration systems, administration systems, business product management, insurance management system , Online Quote Engine, automation, Commission Statements Automation, insurance agency / agent management systems, Producer Profile Management, Online Event Management, insurance  management systems, insurance document management, insurance applications, web solutions for Insurance Company, Software for Insurance Industry, IT Solutions for Health Insurance, erp software, software development, insurance solution, Insurance solutions, insurance management software, insurance online, insurance software solutions, Insurance, Insurance consulting, Insurance technology, insurance software, insurance software solutions, insurance crm software, Insurance application, Insurance software, Insurance solution, Insurance solutions, Insurance system, Policy administration, Accenture Insurance Solution, claim component, claim components, claim handling, claim software, claim solution, claim system, claims software "
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Insurance</h1>
            <h5>Insurance</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                At Avani Cimcon Technologies, we have significant experience of Insurance domain.
                Today the Insurance providers are seeking new and innovative methods to conduct
                business. So it is very difficult for IT solution providers to respond quickly to
                new application requirements. We understand this criticality and work as a support
                system for solution providers and internal IT staffs by developing those solutions
                as well as consulting, systems development, support and maintenance services.
              </p>

              <p>
                <b>
                  We have worked on developing an array of solutions for the Insurance industry. We
                  have developed solutions like:
                </b>
              </p>

              <ul className="mb-4">
                <li className="mb-2">
                  <b className="text-primary">Policy Administration/New Business Processing</b>
                </li>
                <li className="mb-2">
                  <b className="text-primary">Licensing and Contracting solutions</b>
                </li>
                <li className="mb-2">
                  <b className="text-primary">Customized e-portals</b>
                </li>
                <li className="mb-2">
                  <b className="text-primary">Illustrations software</b>
                </li>
                <li className="mb-2">
                  <b className="text-primary">Business Rules Engine</b>
                </li>
                <li className="mb-2">
                  <b className="text-primary">CRM</b>
                </li>
                <li>
                  <b className="text-primary">SOX compliant Document Management System</b>
                </li>
              </ul>

              <p>
                Leveraging our knowledge of the industry we have successfully designed and
                implemented a middleware solution, allowing organizations to interface their
                Internet enabled applications with legacy systems written in COBOL. This is a major
                development and allows clients with previously limited options – because of COBOL –
                to take advantage of current technologies.
              </p>

              <p>
                Our services portfolio includes implementation services, customization, and
                consulting and designing services. Interfacing .Net applications and J2EE
                applications with legacy COBOL applications is one of our specialties. This allows
                organizations to preserve their expensive investments in legacy systems but still be
                able to provide their consumers with state of the art e-enabled business front ends.
              </p>

              <p>
                <b>
                  We have worked with our clients on the following Agency Solutions and E-commerce
                  Solutions specific for Insurance domain.
                </b>
              </p>

              <div className="row">
                <div className="col-lg-6">
                  <div className="shadow-sm p-3">
                    <h4 className="text-primary mb-3 font-weight-bold">Agent solutions:</h4>
                    <ul>
                      <li className="mb-2">Illustration Systems</li>
                      <li className="mb-2">Marketing Portal</li>
                      <li className="mb-2">Personal Information Manager</li>
                      <li className="mb-2">Evaluation System</li>
                      <li className="mb-2">Business Rule Engine</li>
                      <li className="mb-2">New Business Processing</li>
                      <li>Licensing and Contracting Portal</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="shadow-sm p-3">
                    <h4 className="text-primary mb-3 font-weight-bold">E-Commerce Solutions:</h4>
                    <ul>
                      <li className="mb-2">Policy Tracking system </li>
                      <li className="mb-2">Commission Statements Automation </li>
                      <li className="mb-2">Forms Store </li>
                      <li className="mb-2">Document Management &amp; Forms Engine </li>
                      <li className="mb-2">Producer Profile Management </li>
                      <li className="mb-2">Pending Commissions </li>
                      <li>Online Event Management </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Insurance;
