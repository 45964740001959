import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/healthcare.png";
import { Helmet } from "react-helmet";

class Healthcare extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Health Care Software Solution, Health Care Management System, Healthcare It Solutions,
            Custom Software Solutions for Health Care Industry â€“ Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Health care management system, healthcare it solutions, Solution for health care, IT solution for health care, health care software development company, software solution for health care industry, custom software development, IT consulting for health care, heath care technology consulting, software developer company, Custom Software Solutions for Health Care Industry, custom software services, custom health care software services, health care solutions software, hospital software, Healthcare Software solutions â€“ healthcare software solutions, patient management system, patient information system, Hospital Management Systems India"
          />
          <meta
            name="description"
            content="ACT offers expert custom software application development and Enterprise Application Integration (EAI) services in the areas of healthcare industry verticals. Health care software solutions and consulting Services Company, Enterprise Application Integration using Service Oriented Architecture (SOA), Hospital Information Management System, Patient Management System / Patient Information System. ACT team merges industry and technology information to develop software particularly for the needs of healthcare industry."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Healthcare</h1>
            <h5>Healthcare</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies (ACT) provides expert custom software application
                development and Enterprise Application Integration (EAI) services in the areas of
                healthcare industry verticals.
              </p>

              <p>
                At ACT, healthcare industry is one of our major business focus area. We have done
                number of projects in healthcare industry verticals like Hospitals,
                Clinics/Polyclinics, Healthcare Insurance, Pharmaceutical Companies etc.
              </p>

              <p>
                ACT over the years has worked on multiple projects that are related to healthcare
                industry. This experience has helped ACT developing extensive healthcare business
                domain knowledge and provides the niche services targeting specifically for this
                vertical.
              </p>

              <div className="shadow-sm p-3 mt-2">
                <h4 className="text-primary mb-3 font-weight-bold">
                  Our Capabilities in Healthcare Industry:
                </h4>

                <p>
                  In the Healthcare Industry, services and solutions already offered by ACT are as
                  below but not limited to:
                </p>

                <ul>
                  <li className="mb-2">
                    Enterprise Application Integration using Service Oriented Architecture (SOA)
                  </li>

                  <li className="mb-2">Hospital Information Management System </li>

                  <li className="mb-2">
                    Practice Management System, which encompasses collaboration platform for doctors
                    and patients, which can be accessed in online and offline scenarios.
                  </li>

                  <li className="mb-2">Customer Relationship Management for hospitals</li>

                  <li className="mb-2">Patient Management System / Patient Information System</li>

                  <li className="mb-2">
                    HIPAA compliant Medical Information Exchanging Application Servers.
                  </li>

                  <li className="mb-2">
                    HIPAA compliant Patient and Insurance information web-services
                  </li>

                  <li className="mb-2">
                    Web application, portal for online presence and online sales.
                  </li>

                  <li className="mb-2">
                    Website design services and dynamic content additions, newsgroups and other
                    customizable applications
                  </li>
                </ul>
              </div>

              <div className="shadow-sm p-3 mt-2">
                <h4 className="text-primary mb-3 font-weight-bold">Projects done by ACT:</h4>

                <p>
                  An analysis module forming a part of large HMO management system for analyzing the
                  performance of the hospital units managed by HMO. We have worked on this project
                  for more than three and a half years.
                  <br />
                  <i className="text-primary">Client location: USA.</i>
                </p>

                <p>
                  An expert system module forming a part of large HMO system, which allows
                  codification of procedures followed by different countries. We have worked on this
                  project for more than two years.
                  <br />
                  <i className="text-primary">Client location: USA.</i>
                </p>

                <p>
                  NCS: Custom account receivable and admissions software for nursing homes.
                  <br />
                  <i className="text-primary">Client location: USA.</i>
                </p>

                <p>
                  ACT helps healthcare organizations to realize the potential for administrative and
                  medical cost savings, increased patient satisfaction and more streamlined business
                  processes through a well-thought-out e-Health initiative.
                </p>

                <p>
                  ACT team combine industry and technology knowledge to develop software
                  specifically for the needs of healthcare industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Healthcare;
