import React from "react";
import Slide1 from "../../assets/images/Slide-1.png";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <div className="main-banner">
      <div className="banner-slide">
        <div className="banner-slide-img">
          <img className="w-100" src={Slide1} alt="" />
        </div>
        <div className="banner-slide-content">
          <div className="container">
            <article className="pt-5 text-white w-50">
              <h2 className="mt-3 font-weight-bold text-uppercase">
                PROPEL YOUR BUSINESS TO THE NEXT LEVEL
              </h2>
              <h6 className="font-weight-bold border-bottom pb-3">
                20+ years of experience in providing Tech Solutions & Consulting
              </h6>
              <ul className="list-unstyled p-0 m-0 pt-3">
                <li className="mb-2 d-flex align-items-center">
                  <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                  <span>Clients in over 70 countries</span>
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                  <span>
                    Cutting edge solutions customized for various industry
                    verticals
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                  <span>
                    Proven execution capabilities with a strong technology focus
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                  <span>
                    Ongoing investments in solutions for upgradation and
                    research
                  </span>
                </li>
                <li className="mb-2 d-flex align-items-center">
                  <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
                  <span>
                    Process driven with effective and efficient delivery
                    mechanisms
                  </span>
                </li>
              </ul>
              <Link
                className="mt-4 btn btn-primary btn-sm"
                to="/it-consulting-services"
              >
                Find Out More
              </Link>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
