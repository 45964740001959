import React from "react";

const HomeAbout = () => {
  return (
    <div className="home-about text-justify text-secondary mt-5">
      <div className="container">
        <p>
          Avani Cimcon Technologies provides state-of-art technology solutions and services to
          organizations in different verticals like Travel, Insurance Solutions, Financial Services,
          HealthCare Solutions, Law, Transportation and many more. We have a distinctive combination
          of experience, expertise and cost effectiveness.
        </p>
        <p className="text-dark">
          Leveraging our 20+ years of experience in software development and our domain expertise in
          different verticals, we offer customizable solutions and services in the areas of
          Enterprise Data Exchange, Marketing Automation, Content Management, Business Rules Engine,
          Booking Engines and Central Reservation Systems.
        </p>
        <p>
          Apart from the software products, we also provide turnkey IT solutions to various
          industries with our software development capabilities. We provide IT services right from
          System Architecture Design, Development and Testing to onsite implementation and training.
        </p>
        <p>
          In the dynamic and highly competitive business environment, our solutions and services
          help organizations optimize the use of IT for accessing information in real time, reduce
          communication costs, become more competitive and improve resource efficiency and
          productivity.
        </p>
        <p className="text-dark">
          Our mission is "To deliver strategic value through consistent delivery of Technology
          Solutions, at an extremely Competitive Price, resulting in Comprehensive Business
          Results."
        </p>
      </div>
    </div>
  );
};

export default HomeAbout;
