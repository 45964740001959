import React, { Component } from "react";

class ContactInfo extends Component {
  state = {};
  render() {
    return (
      <div className="shadow-sm p-4">
        <h5 className="text-primary mb-4">Email Us:</h5>
        <ul className="p-0 m-0">
          {/* <li className="mb-3 d-flex align-items-center">
            <i className="fa fa-envelope mr-2" aria-hidden="true" />
            <b className="mr-2">Sales :</b>
            <a className="text-primary" href="mailto:sales@avanicimcon.com">
              sales@avanicimcon.com
            </a>
          </li> */}
          <li className="mb-2 d-flex align-items-center">
            <i className="fa fa-envelope mr-2" aria-hidden="true" />
            <b className="mr-2">Info :</b>
            <a className="text-primary" href="mailto:inquiry@avanicimcon.com">
              inquiry@avanicimcon.com
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default ContactInfo;
