import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/domestic-project.png";
import { Helmet } from "react-helmet";

class DomesticProjects extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Domestic Projects, Software Development Projects - avanicimcon.com</title>
          <meta
            name="keywords"
            content="Comprehensive E-commerce site (Product), Ecommerce, Transport Information System, Project Management and Inventory, Disaster Management System, ERP, web based site maintenance, E-com module, client relationship management module, website customization."
          />
          <meta
            name="description"
            content="Avani Cimcon Technologies Ltd has prosperity of skills with adaptation, restoration of domestic projects of e-commerce site for an office automation retail chain, turnkey basis right from the system design and specifications to implementation and hosting. Some of the features are fully database driven, web based site maintenance, catalogue maintenance, E-com module, comprehensive order processing module, client relationship management module, brand stores module, advanced search mechanisms, auction module, site customization, link to LAN based systems."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Domestic Projects</h1>
            <h5>Domestic Projects</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <h2 className="text-primary">Projects done in India</h2>
              <p>Summary of some important software development projects done by us in India.</p>

              <h5 className="text-primary mt-4">Comprehensive E-commerce site (Product):</h5>
              <p>
                Comprehensive e-commerce site for an office automation retail chain (undergoing
                development). We have taken the job on a turnkey basis right from the system design
                and specifications to implementation and hosting. Some of the features are fully
                database driven, web based site maintenance, catalogue maintenance, E-com module,
                comprehensive order processing module, client relationship management module, brand
                stores module, advanced search mechanisms, auction module, site customization, link
                to LAN based systems.
              </p>

              <h5 className="text-primary mt-4">Transport Information System:</h5>
              <p>
                A LAN based information system for transport operator with a geographically
                distributed network.
              </p>

              <h5 className="text-primary mt-4">Project Management and Inventory:</h5>
              <p>
                A LAN based multi-user system for keeping track of the project schedules, budgets,
                inventories and finances.
              </p>

              <h5 className="text-primary mt-4">Disaster Management System:</h5>
              <p>
                Software to simulate disaster conditions in a refinery, which predicts areas
                affected and the resources required. It also incorporates an early warning system.
              </p>

              <h5 className="text-primary mt-4">ERP</h5>
              <p>
                System for a textile plant. It does complete financial analysis, general accounting
                and gives useful MIS reports for multi-company organizations. The system runs under
                windows.
              </p>

              <p>Customized Financial Information System for a number of local businesses.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DomesticProjects;
