import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/travel-hospitality.png";
import { Helmet } from "react-helmet";
import CMSFlow from "../../assets/images/cms_flow.gif";
import CrsVehicle from "../../assets/images/crs_vehicle_img.gif";
import CrsHotel from "../../assets/images/crs_hotel_img.gif";
import CrsRestaurant from "../../assets/images/crs_restaurant_img.gif";
import MiddlewareFlow from "../../assets/images/middleware_flow.gif";

class TravelAndHospitality extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Travel Solutions, Travel Management Solutions, Automated Travel Solutions -
            Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Travel Solutions, Travel Management Solutions, Automated Travel Solutions, travel solution Business Travel Solutions, Online Travel Software, Travel Solutions, ISO Travel Solutions, Travel software: Providers and solutions, Travel Management Software, Advanced travel technology solution, travel software solutions provider, travel software solutions, travel industry solutions, solutions for hospitality, online travel software, online travel software solutions"
          />
          <meta
            name="description"
            content="We are travel software solutions provider for the Travel Industry ISO certified Travel Solutions - Software solutions for the tourism industry decreases the requirement for multiple software systems; it helps maintain constancy of all data all through all back-office process international travel and tourism industry along with Management agencies dynamic packaging a complete travel solutions. We have advanced travel technology solution with dynamic packaging. Our solutions fulfill the IT needs of Travel Vendors/Providers, Destinations, Associations, Agents, Online Travel Portals, Aggregators and Consolidators."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Travel & Hospitality</h1>
            <h5>On-Demand Software Solutions for Travel Industry</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                In the dynamic and highly competitive environment of the Travel Industry, our plug n
                play solutions allows organizations to expand the direct distribution opportunities,
                control relationships, lower technology costs & enhance marketing communication with
                customers and prospects. Our ready to deploy solutions offers the marketing and
                management team with real-time analytics and dashboards to better help compete in
                today’s cutthroat environment.
              </p>
              <p>
                Our solutions cater to the IT needs of Travel Vendors/Providers, Destinations,
                Associations, Agents, Online Travel Portals, Aggregators and Consolidators.
              </p>
              <p>
                Leveraging our 15 years of experience in software development and our domain
                expertise in the Travel Industry, we offer customizable solutions like:
              </p>

              <ul>
                <li className="text-primary mb-2">
                  <b>
                    DXchange – XML Web Services based OTA (OpenTravel Alliance) compliant Middleware
                  </b>
                </li>
                <li className="text-primary mb-2">
                  <b>CARMA – Marketing Automation Suite</b>
                </li>
                <li className="text-primary mb-2">
                  <b>Content Management System</b>
                </li>
                <li className="text-primary mb-2">
                  <b>Social Networking</b>
                </li>
                <li className="text-primary mb-2">
                  <b>Business Rules Engine</b>
                </li>
                <li className="text-primary mb-2">
                  <b>Booking Engine</b>
                </li>
                <li className="text-primary">
                  <b>Inventory Management System / eCRS</b>
                </li>
              </ul>

              <div className="shadow-sm p-3">
                <h4 className="text-primary mb-3 font-weight-bold">DXchange</h4>
                <p>
                  DXchange is an OTA (OpenTravel™ Alliance) compliant XML web services based Data
                  Exchange Middleware Server. It is fully scalable and easily configurable. It
                  provides travel organizations/Web Portals the ability to bundle XML requests to
                  multiple suppliers in a single request, aggregate and consume XML or any form of
                  online data from suppliers. For suppliers, DXchange enables them to expose their
                  Inventory System / CRS as OTA compliant Web Services in a cost effective and
                  secure manner giving them a wider reach. Additionally, it can support other
                  standards with minimal customization.
                </p>
                <p>
                  DXchange is available in three versions: Lite, Professional and Enterprise. It is
                  platform independent and can connect to heterogeneous systems. It is hosted at the
                  customer’s site and is sold as an annual license fees model, instead of
                  transaction based fees / commissions model. DXchange is available on .net and Java
                  platforms.
                </p>

                <div className="text-center mb-3">
                  <img src={MiddlewareFlow} alt="" />
                </div>
                <p className="text-primary font-weight-bold">
                  A powerful web-based Business Rules Engine can be integrated with DXchange at all
                  levels, with an easy to use web interface. This provides an organization with:
                </p>

                <ul>
                  <li className="mb-2">
                    The ability to monitor and control the flow of data to their system{" "}
                  </li>
                  <li className="mb-2">
                    The flexibility to make changes to the business rules to adapt to the travel
                    industry's ever-changing business requirements without any change to the code.
                  </li>
                  <li>
                    Customization can be provided to allow their work with familiar interface to
                    suit their requirements
                  </li>
                </ul>

                <p className="text-primary font-weight-bold">
                  DXchange, an Innovative Solution, has been nominated as one of the Top 100 IT
                  Innovations for 2007 by Nasscom, India.
                </p>

                <div className="row">
                  <div className="col-lg-6">
                    <ul>
                      <li className="mb-2">Scalable Services Oriented Architecture</li>
                      <li className="mb-2">Built-in Business Rules Engine</li>
                      <li className="mb-2">24x7 Error Management system</li>
                      <li className="mb-2">Aggregates responses from multiple suppliers</li>
                      <li className="mb-2">
                        Secured access layer to multiple systems, components and data sources
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      <li className="mb-2">
                        Platform Agnostic – Connects to diverse platforms and data sources
                      </li>

                      <li className="mb-2">Enables usage of existing legacy applications</li>
                      <li className="mb-2">Optional Accounting and Billing modules</li>
                      <li className="mb-2">Faster and better ROI</li>
                      <li className="mb-2">
                        Compatibility with Industry standard XML specifications <br />
                        (OTA and TTI)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h4 className="text-primary mb-2 font-weight-bold">Content Management System</h4>
                <h6 className="text-primary mb-3">
                  A multilingual capable System for Do-it-Yourself web and Social Networking portals
                </h6>
                <p>
                  Avani Cimcon Technologies' Content Management System (CMS) is your chance to
                  maintain your website and web content yourself, quickly and cost effectively. It
                  works on the concept of "Do-It-Yourself", i.e. you can yourself create web content
                  like images, text, document, web pages etc, store them for reuse, and insert them
                  on your website with no knowledge of HTML and without the help of webmasters or
                  web developers. It is available in four versions:
                </p>

                <ul className="row">
                  <li className="col-lg-3 text-primary font-weight-bold mb-2">CMS Lite</li>
                  <li className="col-lg-9 text-primary font-weight-bold mb-2">
                    CMS Social Networking
                  </li>
                  <li className="col-lg-3 text-primary font-weight-bold mb-2">CMS Travel Portal</li>
                  <li className="col-lg-9 text-primary font-weight-bold">CMS Destination Portal</li>
                </ul>

                <div className="row mt-4">
                  <div className="col-lg-3 d-flex align-items-center">
                    <div>
                      <img src={CMSFlow} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <ul>
                      <li className="mb-2">Create and Launch Travel Portals/Websites Easily</li>
                      <li className="mb-2">
                        Do-It-Yourself: Update, reuse web content as and when needed
                      </li>
                      <li className="mb-2">WYSIWYG site builder</li>
                      <li className="mb-2">SEO friendly: Crates Search Engine Optimized pages</li>
                      <li className="mb-2">Easily submit your site to search engines</li>
                      <li className="mb-2">
                        Integration with Booking Engine and Dynamic Packaging
                      </li>
                      <li className="mb-2">
                        Domain Specific content: Host the same content with minor modifications for
                        multiple domains
                      </li>
                      <li className="mb-2">
                        Newer business modules, RSS etc., can be easily integrated
                      </li>
                      <li className="mb-2">
                        Content management allows sharing of content between multiple applications
                        like marketing automation suite
                      </li>
                      <li className="mb-2">
                        Linked contact management for storing of site visitors and their information
                        and preferences for marketing campaign.
                      </li>
                    </ul>
                  </div>
                </div>

                <h5 className="text-primary mt-3">CMS Lite</h5>
                <p>
                  CMS Lite is an ideal, out of the box content management solution specially
                  packaged for small companies and professionals who want a presence on the Web and
                  would want to update the content themselves.
                </p>

                <h5 className="text-primary mt-4">CMS Social Networking</h5>
                <p>
                  Out of the box social networking solution allows implementation of current
                  consumer trends like blog, broadcast, forums and other means of sharing
                  information.
                </p>

                <h5 className="text-primary mt-4">CMS Travel Portal</h5>
                <p>
                  It is a totally customizable solution designed for small to large
                  enterprises/portals to launch their web sites as quickly and as cost efficiently
                  as possible. An ideal implementation of this system would be the front-end system
                  to a booking engine. The CMS can be used to update marketing content on a
                  day-to-day or a weekly basis.
                </p>

                <h5 className="text-primary mt-4">CMS Destination Portal</h5>
                <p>
                  It is a templated solution which allows you to manage your destination content
                  with rich media. It has built-in modules to display complete information about the
                  destination allowing the users to have a more complete view of what destination
                  is, what are the destination’s values, easily find places to stay, what to do,
                  what activities are there, property-wise web pages, brochures, tickets and loads
                  of information modules about the destination. It provides a complete travel
                  experience to the users and allows them to navigate and book their entire travel
                  package with the travel products available with for sell, like airlines, hotels,
                  vehicles, restaurants and excursions.
                </p>
                <p>
                  Having a continuously updated website is a mandatory requirement to survive and
                  grow in the dynamic highly competitive environment of the travel industry. But at
                  the same time keeping content continuously updated is expensive and time
                  consuming. CMS not only facilitates content management, but also gives you
                  independent and full control of your website right from the simple ones to the
                  most complicated.
                </p>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h4 className="text-primary mb-3 font-weight-bold">Booking Engine</h4>
                <p>
                  Avani Cimcon Technologies offers a complete XML web services based booking engine
                  for Hotels, Vehicles, Restaurants, Airlines and Excursions. It is easy to use,
                  highly scalable and flexible. It can be easily integrated with the website/CMS. It
                  can be integrated with multiple inventory systems through our middleware server
                  DXchange. It can also access inventory of other vendors along with your own
                  inventory and show aggregated results. It allows multiple currencies and enables
                  users to select and pay in their currency. Rules can be applied on the fly so as
                  to be able to offer variable pricing and incentives to the customers. It allows
                  doing availability search, aggregation and booking of travel products offered by
                  the providers.
                </p>
                <b className="text-primary font-weight-bold mb-2 d-block">Salient Features</b>
                <ul>
                  <li className="mb-2">Web based booking engine running off XML</li>
                  <li className="mb-2">
                    All elements and pages are customizable to the needs of the customer
                  </li>
                  <li className="mb-2">White label sites available</li>
                  <li className="mb-2">
                    Provision for display in multiple currencies with base currency
                  </li>
                  <li className="mb-2">Allows all currency changes</li>
                  <li className="mb-2">
                    Allows rules on the fly so as be able to offer variable pricing and incentives
                    to the customer
                  </li>
                  <li className="mb-2">Can add on editable content pages</li>
                  <li className="mb-2">Integration with Website</li>
                  <li className="mb-2">
                    Can access inventory of other vendors along with own inventory and show
                    aggregated results. This is in case the vendors have their own system
                  </li>
                  <li className="mb-2">
                    Customer registration and management system. Allows capture of customer
                    information which can be used for loyalty programs and incentives
                  </li>
                  <li>
                    Over and above the booking engine solution we can have amenities / facilities /
                    specialty services section in the booking engine. This section would be useful
                    for customers asking for special facilities and services
                  </li>
                </ul>
              </div>

              <div className="shadow-sm p-3 mt-4">
                <h4 className="text-primary mb-3 font-weight-bold">Inventory Systems / eCRS</h4>
                <p>
                  Avani Cimcon Technologies offers complete web-based Inventory and Reservations
                  systems for Vehicles (Cars and Buses), Hotels and Restaurants. These systems allow
                  the owners to configure business and manage their inventory. These systems are
                  broadly divided into – Super admin and Member admin so as to provide sufficient
                  security and introduce a certain level of compliance.
                </p>

                <h5 className="text-primary mt-4 pt-3 border-top mb-3">
                  Vehicles Reservation System
                </h5>
                <p>
                  It is a complete web-based system for cars and buses. It allows the vehicle owners
                  to configure business and manage the inventory. It also allows setting up vehicle
                  category, vendors, agents, location, pricing, etc. The setup done in the inventory
                  system will be reflected in the booking system from where the end users will do
                  their bookings.
                </p>
                <b className="text-primary font-weight-bold mb-2 d-block">Salient Features</b>
                <p>
                  The Vehicle Reservation System is developed for seamless online reservation,
                  communication and payment solution for transportation business. Some of the
                  salient features of the system are as follows:
                </p>

                <div className="row mt-4">
                  <div className="col-lg-3 d-flex align-items-center">
                    <div>
                      <img src={CrsVehicle} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <ul>
                      <li className="mb-2">Enables setting up member and his businesses</li>
                      <li className="mb-2">
                        Provides interface for member to manage his business by setting different
                        required entities
                      </li>
                      <li className="mb-2">
                        Provides interface to setup – Vehicle Category, Vehicle Details, Locations,
                        Price, Stop Sell, Seasons and Amenities
                      </li>
                      <li className="mb-2">
                        Provides data security, confidentiality and restricted access for the admin
                        site
                      </li>
                      <li className="mb-2">
                        Member can set up various policies and also customize his web page that will
                        be reflected in the booking interface
                      </li>
                      <li className="mb-2">
                        Provides different interfaces for online bus reservation
                      </li>
                      <li className="mb-2">
                        Customizable to manage bookings for limousines, ATVs and Chauffeur driven
                        services
                      </li>
                      <li>The dynamic dashboard displays the business statistics at a glace</li>
                    </ul>
                  </div>
                </div>

                <h5 className="text-primary mt-4 pt-4 border-top mb-3">Hotel Reservation System</h5>
                <p>
                  It is a complete web-based Hotel Reservation System. It allows the property owners
                  to configure business and manage the inventory. It allows setting up rooms,
                  allocation, rates etc. The setup done here will be reflected in the Hotel Booking
                  System from where the end users will do their bookings.
                </p>
                <b className="text-primary font-weight-bold mb-2 d-block">Salient Features</b>
                <p>
                  The Hotel Reservation System is developed for seamless online reservation,
                  communication and payment solution for Hotel business. Some of the salient
                  features of the system are:
                </p>

                <div className="row mt-4">
                  <div className="col-lg-3 d-flex align-items-center">
                    <div>
                      <img src={CrsHotel} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <ul>
                      <li className="mb-2">Enables setting up member and his businesses</li>
                      <li className="mb-2">
                        Provides interface for members to manage his business by setting different
                        required entities
                      </li>
                      <li className="mb-2">
                        Provides interface to setup – Room types, Amenities at room level and hotel
                        level, room allocation, rates, stop sell, etc
                      </li>
                      <li className="mb-2">
                        Property owner can manage accommodation &amp; bookings, setup rates &amp;
                        allocation for various room types, upload images, enter details of special
                        deals/offers, setup various policies &amp; much more
                      </li>
                      <li className="mb-2">
                        Provides different interface to setup Promotions &amp; Packages. Member can
                        set blackout dates for the same
                      </li>
                      <li className="mb-2">
                        Provides data security, confidentiality and restricted access for the admin
                        site
                      </li>
                      <li className="mb-2">
                        The dynamic dashboard displays the business statistics at a glace
                      </li>
                      <li className="mb-2">
                        Members can setup various policies and also customize his webpage that will
                        be reflected in booking interface
                      </li>
                      <li>Provides an interface to manage &amp; control bookings</li>
                    </ul>
                  </div>
                </div>

                <h5 className="text-primary mt-4 pt-4 border-top mb-3">
                  Restaurant Reservation System
                </h5>
                <p>
                  It is a complete web-based Restaurant Reservation System. It allows the restaurant
                  owners to configure business and manage the inventory. It allows setting up
                  sessions, table allocations, rates, etc. The setup done here will be reflected in
                  the Restaurant Booking System from where the end users will do their bookings.
                </p>
                <b className="text-primary font-weight-bold mb-2 d-block">Salient Features</b>
                <p>
                  The Restaurant Reservation System is developed for seamless online reservation,
                  communication and payment solution for Restaurant business. Some of the salient
                  features of the system are:
                </p>

                <div className="row mt-4">
                  <div className="col-lg-3 d-flex align-items-center">
                    <div>
                      <img src={CrsRestaurant} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <ul>
                      <li className="mb-2">Enables setting up member and his businesses</li>
                      <li className="mb-2">
                        Provides interface for members to manage his business by setting different
                        required entities
                      </li>
                      <li className="mb-2">
                        The dynamic Dashboard displays the business statistics at a glance.
                      </li>
                      <li className="mb-2">
                        Provides interface to setup – Locations, Sessions, Cuisines, Table Type,
                        Amenities, Restaurant Details, Restaurant description, Menu, Beverages,
                        Rates and Stop Sell
                      </li>
                      <li className="mb-2">
                        Members can manage bookings made for the restaurant. On viewing booking
                        details, he can also confirm or cancel the booking
                      </li>
                      <li className="mb-2">
                        Member can setup various policies and also customize his webpage that will
                        be reflected in booking interface
                      </li>
                      <li>
                        Provides data security, confidentiality and restricted access for the admin
                        site
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <p className="mt-4">
                For more details please visit{" "}
                <a
                  className="text-primary"
                  rel="noopener noreferrer"
                  href="http://travel.avanicimcon.com"
                  target="_blank"
                >
                  <b>http://travel.avanicimcon.com</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TravelAndHospitality;
