import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/international-project.png";
import { Helmet } from "react-helmet";

class InternationalProjects extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            International Projects, International Business projects, Offshore Development -
            avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="International projects, Online Car hire system and Web services, Middleware Project., Online Travel portal and Web services, ICT portal, CRM site, Modules for a financial services company, FMS: Financial Management System, STTS: Smart Trouble Ticket System: Software for help desks, Inventory System, NCS Custom account receivable and admissions software for nursing homes, CARMS, Waiting list maintenance, Warehousing, SARMS, Destination Portal, Purchase management and monitoring system, Executive Information System"
          />
          <meta
            name="description"
            content="Our client is one of the largest independent Car Rental agents online with more than 3000 car hire locations available throughout 39 countries. This online portal is one of the leading online car rental agents in the market with more and more locations and cars added regularly. It is one of the largest independent Car Rental agent's online offering secure online Car Rental bookings for major Airports and cities around the world. It offers to rent a car by booking either online, on the phone, or via e-mail. We are also developing a XML web service, which would help our client expand their business multiple fold based on Open Travel Alliance standards."
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">International Projects</h1>
            <h5>International Projects</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <h2>International projects</h2>
              <p>
                Given below is the summary of some of the important international projects developed
                offshore by Avani Cimcon Technologies.
              </p>

              <h5 className="text-primary mt-4">Online Car hire system and Web services.</h5>
              <p className="text-secondary">Client location: Australia.</p>
              <p>
                Our client is one of the largest independent Car Rental agents online with more than
                3000 car hire locations available throughout 39 countries. This online portal is one
                of the leading online car rental agents in the market with more and more locations
                and cars added regularly. It is one of the largest independent Car Rental agent's
                online offering secure online Car Rental bookings for major Airports and cities
                around the world. It offers to rent a car by booking either online, on the phone, or
                via e-mail. We are also developing a XML web service, which would help our client
                expand their business multiple fold based on Open Travel Alliance standards.
              </p>

              <h5 className="text-primary mt-4">Middleware Project.</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                Our client has a unique business model, which blends a TPA (Third Party
                Administrator) and a Marketing Organization. They have an existing backend system in
                COBOL, for workflow and policy management. The challenge was to develop a middleware
                system which can interact with these systems and streamline the policy application
                process in order to reduce errors and amount of time involved in entering, accessing
                and managing policy information. We have successfully implemented this project
                through 100% offshore model.
              </p>

              <h5 className="text-primary mt-4">Online Travel portal and Web services.</h5>
              <p className="text-secondary">Client location: Australia.</p>
              <p>
                Our client is one of Australia's leading travel wholesalers covering 40 countries
                throughout Europe, the Middle East, India and Latin America. We provide them
                services to develop newer versions of the site and maintain their existing sites. In
                an initiative to co-produce a XML product, which would leverage the firms processes
                and integrate their existing processes to third party agencies, we provide the
                technology back bone to the project.
              </p>

              <h5 className="text-primary mt-4">ICT portal.</h5>
              <p className="text-secondary">Client location: USA</p>
              <p>
                This portal is developed using the dynamic and ever growing portal built on
                Dotnetnuke for an ICT community development. The community's ICT Programme is
                helping to bridge the Technology Divide across the several countries. It is a
                multi-stakeholder partnership aimed at tapping available member country resources to
                fast track positive change. Leaders across various institutions are heading this
                initiative.
              </p>

              <h5 className="text-primary mt-4">CRM site.</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                A web based application, which is an online resource for insurance and financial
                services professionals. It is a unique combination of a marketing communication
                system (news letters, brochures, Customized greeting and event cards inclusive of
                printing and posting), a contact management database and a customizable website (for
                each individual user) that is integrated and works together to help grow the users
                business. It provides an end-end solution in terms of design to printing to delivery
                (news letters, brochures, customized greeting and event cards etc). It is designed
                to target the subscribers, individual clients and prospects and serve them by
                addressing their needs and interests. Cutting edge technology is put to use in
                developing this site. The site consists of separate modules for a better management
                of the whole process. This product has been through three versions and more are
                planned this year and next. We have been working on this project for more than a
                year.
              </p>

              <h5 className="text-primary mt-4">Modules for a financial services company.</h5>
              <p className="text-secondary">Client location: USA</p>
              <p>
                Modules developed for one of the successful market leaders in the financial services
                industry. The site caters to the needs of the company, its contracted Agents and
                their clients. Currently we are in the process of developing and upgrading different
                modules. We have been working on this project for more than two years.
              </p>

              <h5 className="text-primary mt-4">FMS: Financial Management System.</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                There are approx. 1000 installations of this system. We are involved in the
                enhancement of the software and development of add-on modules. We have worked on
                this project for more than three years. An analysis module forming a part of large
                HMO management system for analyzing the performance of the hospital units managed by
                HMO. We have worked on this project for more than three and a half years. An expert
                system module forming a part of large HMO system, which allows codification of
                procedures followed by different countries. We have worked on this project for more
                than two years.
              </p>

              <h5 className="text-primary mt-4">
                STTS: Smart Trouble Ticket System: Software for help desks.
              </h5>
              <p className="text-secondary">Client location: Germany.</p>
              <p>Smart Trouble Ticket System: Software for help desks.</p>

              <h5 className="text-primary mt-4">Inventory System.</h5>
              <p className="text-secondary">Client location: Austria.</p>
              <p>
                A food and beverage inventory controlling software program specifically designed for
                cruise ship needs. The program may be used for equipment and consumables as well as
                gift shop stocks. The software also has a web interface.
              </p>

              <h5 className="text-primary mt-4">
                NCS Custom account receivable and admissions software for nursing homes.
              </h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>Custom account receivable and admissions software for nursing homes.</p>

              <h5 className="text-primary mt-4">CARMS:</h5>
              <p className="text-secondary">Client location: United Kingdom.</p>
              <p>Corporate Account and Recovery Management System.</p>

              <h5 className="text-primary mt-4">Waiting list maintenance</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                Software to be used by housing authorities across USA and follows guidelines as laid
                down by the HUD and the state authorities.
              </p>

              <h5 className="text-primary mt-4">Warehousing</h5>
              <p className="text-secondary">Client location: Germany.</p>
              <p>Software for pharmaceutical organization.</p>

              <h5 className="text-primary mt-4">SARMS</h5>
              <p className="text-secondary">Client location: UK.</p>
              <p>Software to manage the Sheriff's department functions.</p>

              <h5 className="text-primary mt-4">Section-8 housing program management</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                Software to be used by housing authorities across US and follows guidelines as laid
                down by the HUD and the state authorities.
              </p>

              <h5 className="text-primary mt-4">Low rent housing program management</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                Software to be used by the housing authorities across US and follows guidelines as
                laid down by the HUD and the state authorities.
              </p>

              <h5 className="text-primary mt-4">Graphical remote inspection program.</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                This software allows scheduling, tracking and collation of inspections of properties
                in the housing program.
              </p>

              <h5 className="text-primary mt-4">
                Information retrieval and document processing system
              </h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>
                For processing and preparing documentation needed for exports as required by the US
                department of commerce.
              </p>

              <h5 className="text-primary mt-4">Financial accounting system</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>Modules included AR, AP, GL and POS.</p>

              <h5 className="text-primary mt-4">Maintenance material management system.</h5>
              <p className="text-secondary">Client location: France.</p>
              <p>Maintenance material management system.</p>

              <h5 className="text-primary mt-4">Executive Information System.</h5>
              <p className="text-secondary">Client location: Malaysia.</p>
              <p>
                Forms a part of the HR management system. Allows access to company's employee data
                via internet or company LAN.
              </p>

              <h5 className="text-primary mt-4">Purchase management and monitoring system</h5>
              <p className="text-secondary">Client location: USA.</p>
              <p>Used by city councils in the USA to help keep their expenses within budget.</p>

              <h5 className="text-primary mt-4">Destination Portal</h5>
              <p className="text-secondary">Client location: Caribbean.</p>
              <p>
                Our client is one of the most unique and progressive hotel and tourism entities in
                the Americas. The new destination portal will provide a complete experience allowing
                the user to easily find, navigate and book their entire travel package with the
                association's 120+ members. Air, hotel, restaurants and excursions will be among the
                options offered through the portal. In addition, the site will contain rich media
                and social networking capabilities along with Association Member tools that will
                allow for Customer Relationship Management and site specific destination analysis.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InternationalProjects;
