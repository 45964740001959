import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/dotnetnuke-development.png";
import { Helmet } from "react-helmet";

class DotNetNukeDevelopment extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>DotNetNuke (DNN) India-DNN Technology Solutions, DNN Module, DNN Project</title>
          <meta
            name="keywords"
            content="Software Development (India), Software Product Development (India), Dotnetnuke (DNN), DNN, dnn, dotnetnuke (DNN) modules, dotnetnuke (DNN) development, dotnetnuke (DNN) portal,  dnn modules, dotnetnuke (DNN) hosting, module dotnetnuke (DNN), dotnetnuke (DNN) css, modules for dotnetnuke (DNN), dotnetnuke (DNN) services, dotnetnuke (DNN) skin, dotnetnuke (DNN) offshore, dotnetnuke (DNN) India, dotnetnuke (DNN) outsource, .net TreeView, .net Combo, .net List, .net Image, .net CustomModule, Software Development, Software Development Services, IT Services"
          />
          <meta
            name="description"
            content="DotNetNuke(DNN) India Technology Software Solutions includes DotNetNuke (DNN) modules, DotNetNuke (DNN) projects. .net TreeView, .net combo, .net list, .net Image, .net CustomModule"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">DotNetNuke Development</h1>
            <h5>DotNetNuke Development</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                DotNetNuke is one of the fast emerging technologies in the Content Management
                systems. Avani Cimcon Technologies has been a pioneer in this DotNetNuke technology.
                With our more that 3 years of experience we provide the best possible DotNetNuke
                Solutions using this ever-evolving technology.
              </p>

              <p>
                Our DotNetNuke Modules have been built using AJAX Technology. This allows for an
                enhanced user interface experience and is extremely fast and efficient in
                presentation of data. We mould our products and services in DotNetNuke on the basis
                of our experience in executing projects, developing custom systems and our
                experience in WYSIWYG User Interfaces. We build DotNetNuke modules that are
                practical and can be productive right from time of installation.
              </p>

              <p>
                After having worked on numerous DotNetNuke modules and DotNetNuke projects, we
                decided that a tree interface could easily be the most usable interface globally
                because unless otherwise specified, all interfaces can be displayed in a Tree
                categorizing and sub-categorizing it.
              </p>

              <h5 className="text-primary mt-4 mb-3">
                Some of our DotNetNuke modules are listed below:
              </h5>

              <ul>
                <li className="mb-2">ACTL.netTree</li>
                <li className="mb-2">ACTL.netCombo</li>
                <li className="mb-2">ACTL.netCustomModule</li>
                <li className="mb-2">ACTL.netImage</li>
                <li className="mb-2">ACTL.netList</li>
                <li>ACTL.TextHTNL</li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DotNetNukeDevelopment;
