import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/partner.png";
import { Helmet } from "react-helmet";

class PartnersProgram extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Partner Program, Partners Program – avanicimcon.com</title>
          <meta
            name="keywords"
            content="Partner Program, software partners, technology partners, consulting partners, distribution partners, software product solutions and IT services, Software Application, Product solutions"
          />
          <meta
            name="description"
            content="Our partner program is comprised of companies selected by Avani Cimcon Technologies (ACT) for their expertise in systems integration, hardware, software, vertical industries, and other critical services. ACT offers a number of partnership options that focus on delivering a value-added software Product solution or IT services to their targeted customers."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Partners Program</h1>
            <h5>Partners Program</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Our partner program is comprised of companies selected by Avani Cimcon Technologies
                (ACT) for their expertise in systems integration, hardware, software, vertical
                industries, and other critical services. ACT offers a number of partnership options
                that focus on delivering a value-added software Product solution or IT services to
                their targeted customers. We have following options for our partners to join us:
              </p>

              <div className="shadow-sm p-3 mt-2">
                <ul>
                  <li className="mb-3">
                    <b class="text-primary">Software Partners</b>
                    <p>
                      Software partners provide software applications that complement, interoperate
                      and add value to ACT product solutions.
                    </p>
                  </li>

                  <li className="mb-3">
                    <b class="text-primary">Technology Partners</b>
                    <p>
                      Technology partners provide hardware systems, platforms, databases, operating
                      systems, equipment, connectivity and hosting services to support and/or extend
                      ACT product solution.
                    </p>
                  </li>

                  <li className="mb-3">
                    <b class="text-primary">Consulting Partners</b>
                    <p>
                      Consulting partners focus on small and medium business market segments.
                      Presence within a concentrated geographic region and/or in-depth vertical
                      industry knowledge.
                    </p>
                  </li>

                  <li>
                    <b class="text-primary">Distribution Partners</b>
                    <p>
                      Distribution partners provide sales and support resources to resell ACT
                      software licenses and deliver first-line support. These partners operate in
                      assigned geographies of their reach and generate the business for the ACT
                      software Product solution and IT services.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PartnersProgram;
