import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/microsoft-technologies.png";
import { Helmet } from "react-helmet";

class MicrosoftTechnologies extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Microsoft Technologies Development, .Net Application Development, Microsoft Business
            Solutions Provider - avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Microsoft .Net software development company have expertise in dot net programming, custom software development, ASP. Net, C#, VB.net application development, Microsoft technologies, Microsoft technologies development, Microsoft .net technologies, Microsoft technologies, ASP.Net, ASP, XML, HTML, XHTML, XSL XML-FO, WAP, VB Script, IIS, VB.Net, C#, Visual C++ (ATL), C, Visual Basic, dot net programming, custom software development, vb.net development, c# application development, .Net Developer India, .Net Software Development, .Net Application Development, .Net Programmer India, .Net Outsourcing, .Net Development Outsource, .Net Development Outsourcing India, Microsoft .NET Outsourcing Software, Custom .Net Outsourcing Software, Offshore .Net Development,"
          />
          <meta
            name="description"
            content="Avani Cimcon Technologies (ACT) is one of the pioneers in developing applications in Microsoft technologies. Microsoft .net development, dot net application development, asp.net developers, asp.net development, Microsoft .Net Development in India, .Net Development India using technologies ASP.Net, ASP, XML, HTML, XHTML, XSL XML-FO, WAP, VB Script, IIS, VB.Net, C#, Visual C++ (ATL), C, Visual Basic"
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Microsoft Technologies</h1>
            <h5>Microsoft Technologies</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies (ACT) is one of the pioneers in developing applications in
                Microsoft technologies. We have been developing complex B2B and B2C applications
                using Microsoft technologies, since last 15 years. We have internally developed
                Microsoft technology based frameworks that enable our developers to jump-start into
                software development right away. This is a robust and a versatile generic framework
                that allows us to build business centric applications. The framework is created to
                help speed up development time and build applications with fewer complexities. Using
                these framework, majority of the efforts is focused on the business logic or
                functionality implementation. In the development of this framework we have utilized
                our vast and varied experience gained over a number of years by developing software
                and products for different customers from all over the world. The other key strategy
                has been to invest in training and gaining expertise on industry standard
                development platforms.
              </p>

              <p>
                Having a broad range of technical expertise, ACT helps clients choose the right set
                of technologies to deliver maximum value within the framework of their particular
                projects.
              </p>
              <h5 className="text-primary mt-4 mb-3">
                Our expertise in development in Microsoft Technologies is follows:
              </h5>

              <table className="table table-bordered">
                <thead>
                  <tr className="bg-light">
                    <th colSpan="2">Microsoft Technology Expertise</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="bg-light font-weight-bold">Languages</td>
                    <td>VB.Net, C#, Visual C++ (ATL), C, Visual Basic...</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Web Technologies</td>
                    <td>ASP.Net, ASP, XML, HTML, XHTML, XSL XML-FO, WAP, VB Script, IIS</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Database</td>
                    <td>MS SQL Server 7.0/2000/2005, MS SQL Reporting Services and MS Access</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Middle Tier</td>
                    <td>COM+, Microsoft Commerce Server</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Messaging</td>
                    <td>MS Messaging Queue (MSMQ 3.0)</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Communication</td>
                    <td>.NET Remoting, Web Services (SOAP/HTTP)</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Testing</td>
                    <td>MS Application Test Center, MS web application stress tool</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Documentation</td>
                    <td>NDoc, VBX commenter, ASP C# API Documenting tools</td>
                  </tr>

                  <tr>
                    <td className="bg-light font-weight-bold">Other Products</td>
                    <td>
                      MS Project, MS Visio Architect and MS-Office 2003MS Share Point Portal Server,
                      MS InfoPath 2003, MS Exchange Server
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MicrosoftTechnologies;
