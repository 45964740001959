import React, { Component } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import HomeServices from "../components/home/home-services";
import HomeWhyUs from "../components/home/home-why-us";
import HomeToday from "../components/home/home-today";
import HomeAbout from "../components/home/home-about";
import HomeBanner from "../components/home/home-banner";

class Home extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>Delivering cutting-edge software solutions since 1992</title>
          <meta
            name="description"
            content=" Avani Cimcon is a Software Development Company India, providing customizable solutions and services in the areas of Travel Solutions, Insurance Solutions, Healthcare Solutions, IT Services India, Enterprise Data Exchange, Marketing Automation, Content Management, Business Rules Engine, Booking Engines and Inventory Management Systems to organizations in different verticals with Financial Services and Solutions, HealthCare, Law, Transportation and IT solutions."
          />
          <meta
            name="keywords"
            content="custom software development, software solution application, software development, software development consulting, software development services, web development software, it solutions and services, it services, it solutions, travel solutions, insurance solutions, financial services, travel solution provider, online travel solutions, online booking software, booking engines, content management systems, software development consultancy, customized content management, content management, booking management, enterprise software solutions (ess), custom software development company, custom software development services, software development, web development consulting, enterprise solutions, sap enterprise performance mgmt, systems architecture design (sad), systems architecture solutions, custom software applications, inventory management, insurance management software, internet booking engines, travel management system, reservation solutions, tour operator software system, business rules engine, business rules management system, brms, business software solutions. "
          />
          <meta name="google-site-verification" content="zyXlIk9KO1aoC7gY47TEiOJTEqZyN0Jtr8VtHZJ0Wps" />
        </Helmet>

        <HomeBanner />

        <div className="mt-5 mb-5">
          <HomeServices />
          <HomeWhyUs />
          <HomeToday />
          <HomeAbout />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
