import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/partner.png";
import { Helmet } from "react-helmet";

class Benefits extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Benefit Partners, Benefits, Partner Program Benefits, Additional Benefits -
            avanicimcon.com
          </title>
          <meta
            name="keywords"
            content="Partner Program, Partner Program Benefits, partner program, Attractive Margins, Extensive Sales and Marketing Support, In-Depth Training, Other benefits"
          />
          <meta
            name="description"
            content="The ACT Partner Program provides additional benefits, as End-to-end solutions for customers, Local support through our partners, Implementation of projects from other business partners, Joint bidding for projects, Post-sales support of partner solutions/products, Product enhancement for existing customers, Remote delivery and technology support"
          />
        </Helmet>
        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Benefits</h1>
            <h5>Benefits</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <h5 className="text-primary mt-4 mb-3">
                The Benefits of ACT Partner Programme include:
              </h5>
              <div className="shadow-sm p-3 mt-2">
                <ul>
                  <li className="mb-3">
                    <b class="text-primary">Attractive Margins:</b>
                    <p>
                      We provide attractive margins and develop win-win business model with our
                      partners.
                    </p>
                  </li>
                  <li className="mb-3">
                    <b class="text-primary">Extensive Sales and Marketing Support:</b>
                    <p>
                      Partners will receive continuous support from the Sales and Marketing team of
                      Avani Cimcon Technologies
                    </p>
                  </li>
                  <li className="mb-3">
                    <b class="text-primary">In-Depth Training:</b>
                    <p>
                      We conduct in-depth product training for partner’s sales and technical
                      representatives.
                    </p>
                  </li>
                  <li>
                    <b class="text-primary">Other benefits:</b>
                    <p>
                      Other benefits include numerous lead generation campaigns, performance
                      benefits and many more.
                    </p>
                  </li>
                </ul>
              </div>

              <h5 className="text-primary mt-4 mb-3">
                The ACT Partner Programme provides additional benefits, such as:
              </h5>
              <div className="shadow-sm p-3 mt-2">
                <ul>
                  <li className="mb-3">End-to-end solutions for customers</li>
                  <li className="mb-3">Local support through our partners</li>
                  <li className="mb-3">Implementation of projects from other business partners</li>
                  <li className="mb-3">Joint bidding for projects</li>
                  <li className="mb-3">Post-sales support of partner solutions/products</li>
                  <li className="mb-3">Product enhancement for existing customers</li>
                  <li>Remote delivery and technology support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Benefits;
