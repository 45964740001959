import React, { Component } from "react";
import ReactGA from "react-ga";
import BannerImg from "../../assets/images/banner/management.png";
import { Helmet } from "react-helmet";

class Management extends Component {
  state = {};
  render() {
    ReactGA.initialize("UA-5235714-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Companies Management, Managing Director, Senior Management - Avanicimcon.com
          </title>
          <meta
            name="keywords"
            content=" Senior Management, managing director, system Architects, Product managers, Project managers, leadership Team, management and executive team, chief executive officer, Systems manager, Quality Assurance manager, Technical manager and Key Account manager. "
          />
          <meta
            name="description"
            content="The Managing Director is a technology strategist, agile manager and entrepreneurial innovator skilled at managing international relationships, with 20 years of IT outsourcing industry experience. He has a postgraduate in Sciences from the US and has traveled extensively over the world. He has extensive experience in Designing, Programming and Implementation of Information and Automation Systems for a variety of businesses. Additionally he has extensive experience of facing customers of all kinds and marketing services in different countries. Our domain expertise includes Travel, Insurance, Financial Services, Healthcare, Transportation, etc."
          />
        </Helmet>

        <div className="page-banner d-flex align-items-center justify-content-center text-white">
          <div className="page-title container position-relative">
            <h1 className="font-weight-bold text-uppercase">Management</h1>
            <h5>Management</h5>
          </div>
          <div className="page-banner-img" style={{ backgroundImage: `url("${BannerImg}")` }}></div>
          <div className="page-banner-overlay"></div>
        </div>

        <div className="inner-page mt-5 mb-5 ">
          <div className="container">
            <div className="text-justify">
              <p>
                Avani Cimcon Technologies is promoted and managed by highly skilled and experienced
                technocrats. The Managing Director is a technology strategist, agile manager and
                entrepreneurial innovator skilled at managing international relationships, with 20
                years of IT outsourcing industry experience. He has a postgraduate in Sciences from
                the US and has traveled extensively over the world. He has extensive experience in
                Designing, Programming and Implementation of Information and Automation Systems for
                a variety of businesses. Additionally he has extensive experience of facing
                customers of all kinds and marketing services in different countries. He is the
                Chief Architect in the organization and provides the vision and leadership. He is on
                the advisory board of a number of companies as a consultant and is involved in
                framing their Business and Information System strategies.
              </p>
              <p>
                Our senior management is a team of technocrats having a combined experience of more
                than 150 years in offshore software development. This includes DBA, System
                Architects, Product managers, Project managers, Systems manager, Quality Assurance
                manager, Technical manager and Key Account manager. They have vast experience in
                major programming languages and databases. Their domain expertise includes Travel,
                Insurance, Financial Services, Healthcare, Transportation, etc.{" "}
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Management;
