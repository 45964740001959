import React from "react";

const HomeToday = () => {
  return (
    <div className="home-today mt-5 mb-5">
      <div className="container">
        <h2 className="page-sub-title text-center mb-4 font-weight-bold">Avani Cimcon Today</h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="shadow text-center p-3 rounded mb-4">
              <i style={{ fontSize: "34px" }} className="fa fa-calendar" aria-hidden="true"></i>
              <h2 className="font-weight-bold mb-2 text-primary">20+</h2>
              <h6>Years in the Industry</h6>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="shadow text-center p-3 rounded">
              <i style={{ fontSize: "34px" }} className="fa fa-smile-o" aria-hidden="true"></i>
              <h2 className="font-weight-bold mb-2 text-primary">200+</h2>
              <h6>Clients Worldwide</h6>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="shadow text-center p-3 rounded">
              <i style={{ fontSize: "34px" }} className="fa fa-globe" aria-hidden="true"></i>
              <h2 className="font-weight-bold mb-2 text-primary">60+</h2>
              <h6>Countries</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeToday;
